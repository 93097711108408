import dayjs from 'dayjs'
import { makeAutoObservable, runInAction } from 'mobx'

import { personService } from '@services'
import { PersonCard } from '@services/models/person'

import { $root as rootStore } from '@stores'

class CabinetStore {
  $root

  constructor(store: typeof rootStore) {
    makeAutoObservable(this)
    this.$root = store
  }

  loggedInLogin = ''
  selectedLogin = ''
  data: PersonCard | undefined = undefined

  clear() {
    this.selectedLogin = ''
    this.data = undefined
  }

  SET_LOGGED_IN_LOGIN(value?: string) {
    this.loggedInLogin = value || ''
  }

  SET_SELECTED_LOGIN(value?: string) {
    this.selectedLogin = value || ''
  }

  get isSelfCard() {
    return this.loggedInLogin === this.selectedLogin
  }

  get isSubordinate() {
    return this.data?.isSubordinate === '1'
  }

  get canSeePersonalEvolution() {
    return (
      this.isSelfCard ||
      this.$root.$user.hasPerm('PERM_IPR_READ') ||
      (this.$root.$user.hasPerm('PERM_IPR_MANAGER') &&
        this.$root.$user.hasPerm('PERM_IPR_READ') &&
        this.isSubordinate)
    )
  }

  get canSeeSkillReview() {
    return (
      this.isSelfCard ||
      this.$root.$user.hasPerm('PERM_SKILL_REVIEW_READ') ||
      (this.$root.$user.hasPerm('PERM_SKILL_REVIEW_MANAGER_READ') && this.isSubordinate)
    )
  }

  get canSeeAbsence() {
    return (
      this.isSelfCard ||
      this.$root.$user.hasPerm('PERM_ABSENCE_READ') ||
      (this.$root.$user.hasPerm('PERM_ABSENCE_MANAGER_READ') && this.isSubordinate)
    )
  }

  get canSeeGoalsPerformance() {
    return (
      this.isSelfCard ||
      this.$root.$user.hasPerm('PERM_PLAN_PERFORMANCE_READ') ||
      (this.$root.$user.hasPerm('PERM_PLAN_PERFORMANCE_MANAGER_READ') && this.isSubordinate)
    )
  }

  get canSeeTasks() {
    const isSelf =
      this.isSelfCard &&
      this.$root.$user.hasPerm('PERM_TASK_PERSONAL') &&
      (this.$root.$user.hasPerm('PERM_TASK_READ') ||
        this.$root.$user.hasPerm('PERM_TASK_CURRENTAUTHOR_READ'))

    const isOther =
      !this.isSelfCard &&
      (this.$root.$user.hasPerm('PERM_TASK_READ') ||
        this.$root.$user.hasPerm('PERM_TASK_CURRENTAUTHOR_READ')) &&
      (!this.$root.$user.hasPerm('PERM_TASK_MANAGER') || this.isSubordinate)

    return isSelf || isOther
  }

  get canAddTasks() {
    const isSelf =
      this.isSelfCard &&
      this.$root.$user.hasPerm('PERM_TASK_ADD') &&
      this.$root.$user.hasPerm('PERM_TASK_PERSONAL')

    const isOther = !this.isSelfCard && this.$root.$user.hasPerm('PERM_TASK_ADD')

    return isSelf || isOther
  }

  get canSeeOnboardings() {
    const isSelf =
      this.isSelfCard &&
      this.$root.$user.hasPerm(['PERM_ONBOARDING_SELF_READ', 'PERM_ONBOARDING_READ'])

    const isOther =
      !this.isSelfCard &&
      this.$root.$user.hasPerm('PERM_ONBOARDING_READ') &&
      (!this.$root.$user.hasPerm('PERM_ONBOARDING_MANAGER') ||
        (this.$root.$user.hasPerm('PERM_ONBOARDING_MANAGER') && this.isSubordinate))

    return isSelf || isOther
  }

  get canShowDetails() {
    return (
      this.isSelfCard ||
      this.canSeeAbsence ||
      this.canSeeGoalsPerformance ||
      this.canSeeTasks ||
      this.canSeeOnboardings
    )
  }

  get canSeeEquipment() {
    return (
      this.isSelfCard ||
      this.$root.$user.hasPerm('PERM_EQUIPMENT_READ') ||
      (this.$root.$user.hasPerm('PERM_EQUIPMENT_MANAGER_READ') && this.isSubordinate)
    )
  }

  get canSeeTransitions() {
    return (
      this.isSelfCard ||
      this.$root.$user.hasPerm('PERM_TRANSITIONS_READ') ||
      (this.$root.$user.hasPerm('PERM_TRANSITIONS_MANAGER_READ') && this.isSubordinate)
    )
  }

  async fetch() {
    const params: any = {}

    if (
      this.isSelfCard ||
      this.$root.$user.hasPerm(['PERM_ABSENCE_READ', 'PERM_ABSENCE_MANAGER_READ'])
    ) {
      params.absenceStartDate = dayjs().format('YYYY-MM-DD')
      params.absenceEndDate = dayjs().add(1, 'year').format('YYYY-MM-DD')
      params.expand = 'personAbsence'
    }

    const response = await personService.fetchOne(this.selectedLogin, params)

    runInAction(() => {
      if (response.isSuccess && response.data[0]) {
        const data = response.data[0]
        this.data = data
        if (this.selectedLogin !== data.nickName) {
          //имя пользователя case sensitive
          this.selectedLogin = data.nickName
        }
      }
    })
  }
}

export default CabinetStore
