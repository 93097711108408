import { useState } from 'react'

import { Dayjs } from 'dayjs'

import { formatDate } from '@helpers/date'

type DateValueType = Dayjs | Date | string | undefined

export const usePeriodFilter = () => {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const handleStartDateChange = (date: DateValueType) => {
    setStartDate(formatDate(date, { format: 'isoDate' }))
  }

  const handleEndDateChange = (date: DateValueType) => {
    setEndDate(formatDate(date, { format: 'isoDate' }))
  }

  const resetDates = () => {
    setStartDate('')
    setEndDate('')
  }

  return {
    startDate,
    setStartDate: (date: DateValueType) => handleStartDateChange(date),
    endDate,
    setEndDate: (date: DateValueType) => handleEndDateChange(date),
    resetDates,
  }
}
