import React from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import { useClassNameByOS } from '@src/hooks'

import DefaultLayout from '@library/layouts/defaultLayout/DefaultLayout'
import SimpleLayout from '@library/layouts/simpleLayout/SimpleLayout'
import AppAuthProvider from '@library/providers/AppAuthProvider'
import PermissionsProvider from '@library/providers/PermissionsProvider'
import PluginsProvider from '@library/providers/PluginsProvider'
import RoleProvider from '@library/providers/RoleProvider'
import SignOnProvider from '@library/providers/SignOnProvider'
import { useWindowSize } from '@library/utils/windowSize'

import { $user } from '@stores'

import AdministrationPage, { getAvailableAdminTabs } from '@pages/admin/AdministrationPage'
import RolesPage from '@pages/admin/roles/RolesPage'
import AnalyticsPage, { getAvailableAnalyticTabs } from '@pages/analytics/AnalyticsPage'
import HCAnalyticsPage from '@pages/analytics/health-checks/HCAnalyticsPage'
import OnboardingsAnalyticsPage from '@pages/analytics/onboardings/OnboardingsAnalyticsPage'
import PepAnalyticsPage from '@pages/analytics/pep/PepAnalyticsPage'
import TaskAnalyticsPage from '@pages/analytics/task/TaskAnalyticsPage'
import LoginPage from '@pages/auth/login/LoginPage'
import CabinetPage from '@pages/cabinet/CabinetPage'
import DepartmentsPage, { getAvailableDepartmentTabs } from '@pages/departments/DepartmentsPage'
import HealthChecksPage from '@pages/departments/health-checks/HealthChecksPage'
import SubordinatesPage from '@pages/departments/subordinates/SubordinatesPage'
import TasksPage from '@pages/departments/tasks/TasksPage'
import MyEmployeesPage, { getAvailableEmployeeTabs } from '@pages/employees/MyEmployeesPage'
import EmployeesListPage from '@pages/employees/persons/EmployeesListPage'
import EmployeesTasksPage from '@pages/employees/tasks/EmployeesTasksPage'
import ErrorPage from '@pages/errors/ErrorPage'
import FeedbackPage from '@pages/feedback/FeedbackPage'
import ProfileNotificationsPage from '@pages/profile/ProfileNotificationsPage'
import ProfilePage from '@pages/profile/ProfilePage'
import CompanyStructurePage from '@pages/reference/CompanyStructurePage'
import JobProfilesPage from '@pages/reference/job-profiles/JobProfilesPage'

import './App.scss'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PluginsProvider>
        <SignOnProvider>
          <RoleProvider>
            <DefaultLayout showNavigation={true} />
          </RoleProvider>
        </SignOnProvider>
      </PluginsProvider>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={'user-profile/'} replace />,
      },
      {
        path: 'administration',
        element: (
          <PermissionsProvider condition={() => $user.hasPerm('PERM_USER_ROLE_ADD')}>
            <AdministrationPage />
          </PermissionsProvider>
        ),
        children: [
          {
            index: true,
            element: <Navigate to={getAvailableAdminTabs()[0]?.route || ''} replace />,
          },
          {
            path: 'roles',
            element: <RolesPage />,
          },
        ],
      },
      {
        path: 'user-profile/:id?/:pageKey?',
        element: <CabinetPage />,
      },
      {
        path: 'departments',
        element: <DepartmentsPage />,
        children: [
          {
            index: true,
            element: <Navigate to={getAvailableDepartmentTabs()[0]?.route || ''} replace />,
          },
          {
            path: 'subordinates',
            element: <SubordinatesPage />,
          },
          {
            path: 'healthchecks',
            element: <HealthChecksPage />,
          },
          {
            path: 'healthchecks/q/:qDate',
            element: <HealthChecksPage />,
          },
          {
            path: 'tasks',
            element: <TasksPage />,
          },
        ],
      },
      {
        path: 'employees',
        element: <MyEmployeesPage />,
        children: [
          {
            index: true,
            element: <Navigate to={getAvailableEmployeeTabs()[0]?.route || ''} replace />,
          },
          {
            path: 'persons',
            element: <EmployeesListPage />,
          },
          {
            path: 'tasks',
            element: <EmployeesTasksPage />,
          },
        ],
      },
      {
        path: 'profile',
        element: <ProfilePage />,
      },
      {
        path: 'company-structure',
        element: <CompanyStructurePage />,
      },
      {
        path: 'job-profiles',
        element: <JobProfilesPage />,
      },
      {
        path: 'analytics',
        element: <AnalyticsPage />,
        children: [
          {
            index: true,
            element: <Navigate to={getAvailableAnalyticTabs()[0]?.route || ''} replace />,
          },
          {
            path: 'peps',
            element: <PepAnalyticsPage />,
          },
          {
            path: 'tasks',
            element: <TaskAnalyticsPage />,
          },
          {
            path: 'healthchecks',
            element: <HCAnalyticsPage />,
          },
          {
            path: 'onboardings',
            element: <OnboardingsAnalyticsPage />,
          },
        ],
      },
      {
        path: 'profile/notifications',
        element: <ProfileNotificationsPage />,
      },
      {
        path: 'feedback',
        element: <FeedbackPage />,
      },
    ],
  },
  {
    element: (
      <PluginsProvider>
        <SimpleLayout />
      </PluginsProvider>
    ),
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
    ],
  },
])

const App = () => {
  useWindowSize()
  useClassNameByOS()

  return (
    <AppAuthProvider>
      <RouterProvider router={router} />
    </AppAuthProvider>
  )
}

export default App
