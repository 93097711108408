import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import styles from './Title.module.scss'

type IProps = {
  children: ReactNode
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  className?: string
}
const Title: FC<IProps> = ({ size, children, className }) => {
  const Tag = ({ children }: { children: ReactNode }) => {
    if (size === 'h1') return <h1>{children}</h1>
    else if (size === 'h2') return <h2>{children}</h2>
    else if (size === 'h3') return <h3>{children}</h3>
    else if (size === 'h4') return <h4>{children}</h4>
    else if (size === 'h5') return <h5>{children}</h5>
    else if (size === 'h6') return <h5>{children}</h5>
    else return <h3>{children}</h3>
  }

  return (
    <div className={classNames(styles.Title, className)}>
      <Tag>{children}</Tag>
    </div>
  )
}

export default Title
