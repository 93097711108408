import React, { FC } from 'react'
import { Pie } from 'react-chartjs-2'

import 'chart.js/auto'
import { observer } from 'mobx-react-lite'

import { TaskAnalyticsOverdueMetrics } from '@services/models/dashboard'

type Props = {
  data: TaskAnalyticsOverdueMetrics[]
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Нарушение сроков реализации',
      position: 'top' as const,
      color: '#000000',
      padding: 10,
      font: { size: 16 },
    },
  },
}

const OverduesChart: FC<Props> = ({ data }) => {
  const labels = ['В срок', 'До 7 дней', 'До 14 дней', 'Более 14 дней']
  const backgroundColors = [
    'rgba(89, 229, 0, 1)',
    'rgba(255, 205, 28, 1)',
    'rgba(255, 145, 227, 1)',
    'rgba(255, 87, 3, 1)',
  ]

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data?.map((item) => item.rate),
        backgroundColor: backgroundColors,
        label: '%',
      },
    ],
  }

  return <Pie data={chartData} options={options} height={300} />
}

export default observer(OverduesChart)
