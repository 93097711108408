/* tslint:disable */
/* eslint-disable */
/**
 * notification
 * Отправка уведомлений пользователям
 *
 * OpenAPI spec version: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

 /**
 * 
 *
 * @export
 * @interface Channel
 */
export interface Channel {

    /**
     * Идентификатор
     *
     * @type {string}
     * @memberof Channel
     */
    id?: string;

    /**
     * Канал уведомления
     *
     * @type {string}
     * @memberof Channel
     */
    channel: ChannelChannelEnum;

    /**
     * Включено/выключено
     *
     * @type {number}
     * @memberof Channel
     */
    value: ChannelValueEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ChannelChannelEnum {
    Mail = 'Mail',
    Mattermost = 'Mattermost',
    HCM = 'HCM'
}
/**
 * @export
 * @enum {string}
 */
export enum ChannelValueEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

