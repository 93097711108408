import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Button,
  DataTable,
  /* @ts-ignore */
  MultiSelect,
  /* @ts-ignore */
  OverflowMenu,
  OverflowMenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { useLoader } from '@src/hooks'

import { formatDate } from '@helpers/date'
import { useRouterSearchParams } from '@helpers/router'
import { isEqualLogin } from '@helpers/text.js'

import LayoutLkPageWrapper from '@library/layouts/LayoutLkPageWrapper'
import CustomAlertModal from '@library/utils/modals/CustomAlertModal'
import OnboardingFormModal, { IViewType } from '@library/widgets/onboardings/OnboardingFormModal'

import { personService } from '@services'
import { Onboarding } from '@services/models/person'

import { $cabinet, $loader, $modal, $user } from '@stores'

import styles from './OnboardingsPage.module.scss'

const OnboardingsPage = () => {
  const searchParams = useRouterSearchParams()
  const initId = searchParams.get('id')
  const [searchString, setSearchString] = useState('')
  const [sortBy, setSortBy] = useState(['id', 'desc'])

  const [loadedList, setLoadedList] = useState<Onboarding[]>([])

  async function loadData() {
    const response = await personService.fetchOnboardings($cabinet.selectedLogin)
    if (response.isSuccess) {
      setLoadedList(response.data?.data || [])
    }
  }

  const isLoading = useLoader(async () => {
    await loadData()
  })

  useEffect(() => {
    if (initId) handleOpen(initId)
  }, [initId])

  const handleAdd = () => {
    $modal.add(OnboardingFormModal, { person: $cabinet.selectedLogin })
  }

  function handleOpen(initId: string, viewType?: IViewType) {
    $modal.add(OnboardingFormModal, { initId, viewType, person: $cabinet.selectedLogin })
  }

  function handleDelete(id: string) {
    $modal.add(CustomAlertModal, {
      title: 'Удаление адаптации',
      message: `Вы точно хотите удалить запись ${id}?`,
      onDone: $loader.registerHandler('onboarding-delete-form', async () => {
        await personService.deleteOnboarding(id)
      }),
      onDoneButtonType: 'danger',
    })
  }

  useEffect(() => {
    const ids = ['onboarding-create-or-update-form', 'onboarding-delete-form']
    const subIds = $loader.subscribe(ids, () => loadData())
    return () => $loader.unsubscribe(ids, subIds)
  })

  const headers = [
    { key: 'startDate', header: 'Дата начала', isSortable: false },
    { key: 'endDate', header: 'Дата окончания', isSortable: false },
    { key: 'dueTo', header: 'Дата закрытия', isSortable: false },
    { key: 'mentor', header: 'Ментор', isSortable: false },
    { key: 'hrpp', header: 'HRPP', isSortable: false },
    { key: 'planLink', header: 'Ссылка', isSortable: false },
  ]

  const rows = useMemo(() => {
    let result = loadedList

    if (_.trim(searchString)) {
      let needle = _.trim(searchString).toLowerCase().split(' ')

      result = result.filter((x: Onboarding) => {
        let full = [
          x.mentor,
          x.hrpp,
          x.planLink,
          //
        ]
          .join(' ')
          .toLowerCase()
        return _.every(needle, (n) => full.includes(n))
      })
    }

    if (sortBy[0] && sortBy[1] !== 'NONE') {
      const rules = [sortBy[0]].map((rule: string) => {
        if (rule === 'id') {
          return (row: Onboarding) => _.toNumber(row.id)
        }
        return rule
      })
      const directions = [sortBy[1].toLowerCase()] as Array<'asc' | 'desc'>
      result = _.orderBy(result, rules, directions)
    }

    return result.map((x: any) => ({
      id: _.toString(x.id),
      startDate: formatDate(x.startDate),
      endDate: formatDate(x.endDate),
      dueTo: formatDate(x.dueTo),
      mentor: x.mentor,
      hrpp: x.hrpp,
      planLink: x.planLink,
    }))
  }, [loadedList, searchString, sortBy])

  return (
    <LayoutLkPageWrapper isLoading={isLoading}>
      <div className="mt-20">
        <DataTable headers={headers} rows={rows} isSortable={false}>
          {({
            rows,
            headers,
            getTableProps,
            getTableContainerProps,
            getHeaderProps,
            getRowProps,
            getToolbarProps,
          }) => {
            return (
              <TableContainer {...getTableContainerProps()} className="customCds">
                <TableToolbar {...getToolbarProps()}>
                  <TableToolbarContent>
                    <TableToolbarSearch
                      placeholder={'Поиск...'}
                      defaultValue={searchString}
                      onChange={(event: any) => setSearchString(event.target?.value)}
                      persistent
                    />
                    {$user.hasPerm('PERM_ONBOARDING_ADD') && !$cabinet.isSelfCard && (
                      <Button onClick={handleAdd} kind="primary">
                        Добавить адаптацию
                      </Button>
                    )}
                  </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()}>
                  <TableHead>
                    <TableRow>
                      {headers.map((header: any) => (
                        <TableHeader
                          {...getHeaderProps({ header })}
                          isSortHeader={sortBy[0] === header.key}
                          isSortable={header.isSortable}
                          sortDirection={_.toUpper(sortBy[1])}
                          onClick={() => {
                            setSortBy([header.key, sortBy[1] === 'asc' ? 'desc' : 'asc'])
                          }}
                        >
                          {header.header}
                        </TableHeader>
                      ))}
                      <TableHeader key="action"></TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow {...getRowProps({ row })}>
                        {row.cells.map((cell) => {
                          if (
                            ['mentor', 'hrpp'].includes(cell.info.header) &&
                            !isEqualLogin(cell.value, $cabinet.selectedLogin)
                          ) {
                            return (
                              <TableCell key={cell.id}>
                                <Link to={'/user-profile/' + cell.value}>{cell.value}</Link>
                              </TableCell>
                            )
                          } else if (cell.info.header === 'planLink') {
                            return (
                              <TableCell key={cell.id}>
                                <a href={cell.value} target="_blank" rel="noreferrer">
                                  {cell.value}
                                </a>
                              </TableCell>
                            )
                          }
                          return <TableCell key={cell.id}>{cell.value}</TableCell>
                        })}
                        <TableCell key={'action:' + row.id}>
                          <OverflowMenu aria-label="overflow-menu" flipped={true}>
                            <OverflowMenuItem
                              itemText="Просмотр"
                              onClick={() => handleOpen(row.id)}
                            />
                            {$user.hasPerm('PERM_ONBOARDING_EDIT') &&
                              !$cabinet.isSelfCard &&
                              !row.cells[2].value && (
                                <OverflowMenuItem
                                  itemText="Редактировать"
                                  onClick={() => handleOpen(row.id, 'edit')}
                                />
                              )}
                            {$user.hasPerm('PERM_ONBOARDING_EDIT') &&
                              !$cabinet.isSelfCard &&
                              !row.cells[2].value && (
                                <OverflowMenuItem
                                  itemText="Закрыть испытательный срок"
                                  onClick={() => handleOpen(row.id, 'close')}
                                />
                              )}
                            {$user.hasPerm('PERM_ONBOARDING_DELETE') && !$cabinet.isSelfCard && (
                              <OverflowMenuItem
                                itemText="Удалить"
                                hasDivider
                                isDelete
                                onClick={() => handleDelete(row.id)}
                              />
                            )}
                          </OverflowMenu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          }}
        </DataTable>
      </div>
    </LayoutLkPageWrapper>
  )
}

export default observer(OnboardingsPage)
