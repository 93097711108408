import React, { FC, ReactNode } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ModalViewer from '@library/utils/modals/ModalViewer'
import UserFeedbackViewer from '@library/widgets/userFeedback/UserFeedbackViewer'

type IProps = {
  children: ReactNode
}
const PluginsProvider: FC<IProps> = ({ children }) => {
  return (
    <>
      {children}
      {/* plugins */}
      <ToastContainer />
      <ModalViewer />
      <UserFeedbackViewer />
    </>
  )
}

export default PluginsProvider
