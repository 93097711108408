import React, { FC, useEffect, useState } from 'react'

import { TrashCan } from '@carbon/icons-react'
import { Button, Column, Row, Select, SelectItem, Stack } from '@carbon/react'
import { observer } from 'mobx-react-lite'

import Title from '@library/ui/title/Title'
import { ModalBase, ModalBody, ModalHeader } from '@library/utils/modals/index'
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store'
import { showErrorAlert } from '@library/utils/toast'
import DepartmentsModal from '@library/widgets/department/DepartmentsModal'

import { catalogService } from '@services'
import { IBodyCustomDepart } from '@services/CatalogService'
import { CustomDepartment, Department } from '@services/models/catalog'
import { UsersRolesListUserRoles } from '@services/models/userPermissions'

import { $filter, $modal } from '@stores'

import styles from './EditCustomManagerModal.module.scss'

interface IProps {
  role: UsersRolesListUserRoles
  nick: string
  _core: IModalItemCore
}

const ViewRoleModal: FC<IProps> = ({ role, nick, _core }) => {
  const [departments, setDepartments] = useState<CustomDepartment[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getUserDepartmens = async () => {
    setIsLoading(true)
    const response = await catalogService.getCustomDepartments(nick)

    if (response.isSuccess && response.data?.data) {
      setDepartments(response.data?.data)
      getAllDepartmens(response.data?.data)
    }
  }

  const addUserDepartmens = async (body: IBodyCustomDepart[]) => {
    const response = await catalogService.addCustomDepartments(body)

    if (response.isSuccess && response?.data?.data) {
      setDepartments((prev) => [...prev, ...response?.data?.data])
      getUserDepartmens()
    }
  }

  const handleDeleteDepartments = async (id: string) => {
    if (departments?.length < 2) {
      showErrorAlert('Должно быть добавлено хотя бы одно подразделение')
    } else {
      const response = await catalogService.deleteCustomDepartments(id)

      if (response.isSuccess) {
        const update = departments?.filter((item) => item?.id !== id)
        setDepartments(update)
        getUserDepartmens()
      }
    }
  }

  const getAllDepartmens = async (departments: CustomDepartment[]) => {
    const response = await catalogService.fetchDepartments(undefined)

    if (response.isSuccess && response.data) {
      const avaibleDeps = response.data[0].data?.filter(
        (item: Department) => !departments.some((dep) => dep.code === item.code),
      )
      $filter.SET_DEPARTMENTS(avaibleDeps)
      $filter.SET_SELECTED_CODES([])
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (nick) {
      getUserDepartmens()
    }
  }, [nick])

  useEffect(() => {
    if ($filter.selectedList?.length > 0) {
      const body = $filter.selectedList?.map((item) => ({ customManager: nick, code: item?.code }))
      addUserDepartmens(body)
      getUserDepartmens()
    }
  }, [$filter.selectedList])

  const handleOpenDepartmentsModal = () => {
    $modal.add(DepartmentsModal, {
      departmentList: $filter.departments,
      onSelect: (departments: any) => {
        if (departments) {
          $filter.SET_SELECTED_CODES(departments)
        }
      },
    })
  }

  return (
    <ModalBase>
      <ModalHeader>
        <Title size="h2">Редактирование подчиненных подразделений</Title>
      </ModalHeader>
      <ModalBody>
        <form id="roles-form">
          <Stack gap={6}>
            <Select id="login" key={nick} labelText="Ник" defaultValue={nick} disabled={true}>
              <SelectItem key={nick} text={nick} value={nick} />
            </Select>
            <Select
              id="role"
              key={role?.role}
              labelText="Роль"
              defaultValue={{ label: role?.roleName, id: role?.role }}
              disabled={true}
            >
              {role?.roleName && <SelectItem key={0} text={role.roleName} value={role?.role} />}
            </Select>

            <>
              <Column style={{ padding: '0px' }}>
                <Button size="sm" onClick={handleOpenDepartmentsModal} disabled={isLoading}>
                  Добавить подразделение
                </Button>
              </Column>
              <div className={styles.departmentList}>
                <Column className={styles.list}>
                  {departments?.length > 0 ? (
                    departments?.map((item) => (
                      <Row className={styles.row} key={item?.id}>
                        <p>{item?.departmentName}</p>
                        <TrashCan
                          onClick={() => handleDeleteDepartments(item?.id as string)}
                          className="cursor-pointer"
                        />
                      </Row>
                    ))
                  ) : (
                    <Row className={styles.info}>
                      <p>Необходимо назначить подразделения для руководителя</p>
                    </Row>
                  )}
                </Column>
              </div>
            </>
          </Stack>
        </form>
      </ModalBody>
    </ModalBase>
  )
}

export default observer(ViewRoleModal)
