import React, { forwardRef, ReactNode, useRef } from 'react'

import classNames from 'classnames'

import DefaultSpinner from '@library/ui/spinner/DefaultSpinner'

import styles from './ModalBody.module.scss'

interface IProps {
  children: ReactNode
  className?: string
  isLoading?: boolean
}

const ModalBody = forwardRef<HTMLDivElement, IProps>(
  ({ children, className, isLoading = false }, forwardedRef) => {
    const defaultRef = useRef<HTMLDivElement>(null)
    const ref = forwardedRef || defaultRef

    return (
      <div
        ref={ref}
        className={classNames(
          'modal-body',
          styles.modalBody,
          isLoading && styles.modalBodyLoading,
          className,
        )}
      >
        {isLoading ? <DefaultSpinner /> : children}
      </div>
    )
  },
)

export default ModalBody
