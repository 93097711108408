import React from 'react'
import ReactDOM from 'react-dom/client'

import { autorun } from 'mobx'

import '@library/utils/toJSpolyfill'

import App from '@src/App'

import { $global } from '@stores'

import { initInstances as initServices } from './services'
import { loadConfig } from './services/config'

// Запуск некоторых скриптов до инициализации приложения
// (аналитика, переводы, загрузку конфигов и т.д.)
autorun(() => {
  if (window.location.hostname !== 'localhost') {
    //
  }
  loadConfig().then((config) => {
    initServices(config)
    $global.SET_IS_READY_FOR_INIT(true)
  })
})

// Инициализация приложение
autorun(() => {
  if ($global.isReadyForInit) {
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
    root.render(<App />)
  }
})
