import React, { useEffect, useState } from 'react'

import { Column, DatePickerInput, FlexGrid, Row } from '@carbon/react'
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from 'chart.js'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'

import { useLoader } from '@src/hooks'

import HcmDatePicker from '@library/ui/datePicker/HcmDatePicker'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'
import PepCountChart from '@library/widgets/analytic/pep/PepCountChart'
import PepList from '@library/widgets/analytic/pep/PepList'
import PepPcntChart from '@library/widgets/analytic/pep/PepPcntChart'
import PepTypePcntChart from '@library/widgets/analytic/pep/PepTypePcntChart'
import DepartmentSelect from '@library/widgets/department/DepartmentSelect'

import { dashboardService } from '@services'
import { IPRAnalytics } from '@services/models/dashboard'

import { $filter } from '@stores'

import { useFirstDepartment } from '@pages/analytics/AnalyticsPage.hooks'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend)

export interface DepartmentStats {
  department: string
  pepCount: number
  personCount: number

  fileTypeOffice: number
  fileTypeConfluence: number
  fileTypeLms: number
}

const propMap = {
  iprcount: 'pepCount',
  personcount: 'personCount',

  filetypeOfficeFile: 'fileTypeOffice',
  filetypeConfluence: 'fileTypeConfluence',
  filetypeLMS: 'fileTypeLms',
}

const PepAnalyticsPage = () => {
  useFirstDepartment()

  const [departmentStats, setDepartmentStats] = useState<DepartmentStats[]>([])

  const pepAnalyticsDate = dayjs($filter.pepAnalyticsDate).toDate()

  const isLoading = useLoader(async () => {
    if ($filter.selectedCodes.length === 0) {
      setDepartmentStats([])
      return
    }

    const response = await dashboardService.getPepAnalytics({
      departments: $filter.selectedCodes,
      date: $filter.pepAnalyticsDate,
    })

    if (response.isSuccess && response.data) {
      const analytics: IPRAnalytics[] = response.data
      const deps = analytics.map((x) => {
        const dep = {
          department: x.departmentName!,
          fileTypeConfluence: 0,
          fileTypeLms: 0,
          fileTypeOffice: 0,
          pepCount: 0,
          personCount: 0,
        }

        x.metrics?.forEach((m) => {
          //@ts-ignore
          const mappedPropName = propMap[m.type!]
          const n = parseInt(m.value!)
          //@ts-ignore
          dep[mappedPropName] = n
        })
        x.complexMetrics?.forEach((cm) => {
          //@ts-ignore
          const mappedPropName = propMap[cm.complexType!]

          cm.metricItems?.forEach((mi) => {
            //@ts-ignore
            const mappedPropName = propMap[cm.complexType! + mi.type!]
            const n = parseInt(mi.value!)
            //@ts-ignore
            dep[mappedPropName] = n
          })
        })
        return dep
      })
      //@ts-ignore
      setDepartmentStats(deps)
    }
  }, [$filter.selectedCodes, $filter.pepAnalyticsDate])

  const onDateChange = (dates: any) => {
    $filter.SET_PEP_ANALYTICS_DATE(dates[0])
  }

  return (
    <FlexGrid fullWidth>
      <Row className="mt-20">
        <Column lg={13}>
          <DepartmentSelect />
        </Column>
        <Column lg={3}>
          <HcmDatePicker onChange={onDateChange} value={pepAnalyticsDate}>
            <DatePickerInput id="date" size="lg" labelText="" hideLabel={true} placeholder="дата" />
          </HcmDatePicker>
        </Column>
      </Row>
      <WithLoaderWrapper isLoading={isLoading}>
        <Row className="mt-20">
          <Column lg={8}>
            <PepCountChart departmentStats={departmentStats} />
          </Column>
          <Column lg={8}>
            <PepPcntChart departmentStats={departmentStats} />
          </Column>
        </Row>
        <Row className="mt-20">
          <Column lg={8}>
            <PepTypePcntChart departmentStats={departmentStats} />
          </Column>
          <Column lg={8}>
            <p></p>
          </Column>
        </Row>
        <Row className="mt-20">
          <Column lg={16}>
            {$filter.selectedCodes.length > 0 && <PepList departments={$filter.selectedCodes} />}
          </Column>
        </Row>
      </WithLoaderWrapper>
    </FlexGrid>
  )
}
export default observer(PepAnalyticsPage)
