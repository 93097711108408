import React, { useEffect, useState } from 'react'

import { Notification } from '@carbon/icons-react'
import { observer } from 'mobx-react-lite'

import { devPlanService, personService } from '@services'
import { getConfig } from '@services/config'

import { $user } from '@stores'

import styles from './TaskCountFrom.module.scss'

const TaskCountFrom = () => {
  const [count, setCount] = useState<string | null>(null)
  const [aidayCount, setAidayCount] = useState<string | null>(null)

  const loadData = async () => {
    const response = await personService.get1CTaskCount($user.nickname)

    if (response.isSuccess) {
      const data = response.data?.activeTasksCounter || ''
      setCount(data)
    }
  }

  const loadDataAiday = async () => {
    const response = await devPlanService.getAidayTaskCount($user.nickname)

    if (response.isSuccess) {
      const data = response.data?.activeTasksCounter || ''
      setAidayCount(data)
    }
  }

  useEffect(() => {
    loadData()
    loadDataAiday()
  }, [])

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        {count && <p className={styles.count}>{count}</p>}
        <Notification size={20} className={styles.bell} />
        <div className={styles.img}></div>
      </div>
      <a href={getConfig().aidayUrl} target="_blank" rel="noreferrer" className={styles.link}>
        <div className={styles.container}>
          {aidayCount && <p className={styles.count}>{aidayCount}</p>}
          <Notification size={20} className={styles.bell} />
          <div className={styles.aiday}></div>
        </div>
      </a>
    </div>
  )
}

export default observer(TaskCountFrom)
