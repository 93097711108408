import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Tag } from '@carbon/react'

import { formatDate } from '@helpers/date'

import PersonPhoto from '@library/ui/personPhoto/PersonPhoto'
import Title from '@library/ui/title/Title'

import { PersonCard as PersonCardType } from '@services/models/person'

import styles from './PersonCard.module.scss'

type IProps = {
  data: PersonCardType | any
}

const PersonCard: FC<IProps> = ({ data }) => {
  if (_.isEmpty(data)) {
    return <></>
  }

  const location = data.location
    ? [
        data.location.country + ', ',
        data.location.city + ' UTC',
        data.location.timeZone <= 0 ? '-' : '+',
        Math.abs(data.location.timeZone),
        ' (MSK' + (data.location.timeZone - 3 <= 0 ? '-' : '+'),
        Math.abs(data.location.timeZone - 3) + ')',
      ].join('')
    : ''

  return (
    <div className={styles.personCard}>
      <div className={styles.userData}>
        <PersonPhoto src={data.photo} />
        <div>
          <div className={styles.nameRow}>
            <Title size="h5">{data.name}</Title>
            <Tag type="high-contrast" className={styles.nickName}>
              {data.nickName}
            </Tag>
          </div>
          <div className={styles.property}>
            <label className={styles.position}>{data.jobPosition}</label>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.property}>
          <label>Подразделение:</label>
          <Link to={`/company-structure/?search=${encodeURIComponent(data.department.name)}`}>
            {data.department.name}
          </Link>
        </div>
        {data.functionalManager && (
          <div className={styles.property}>
            <label>Фактический руководитель:</label>
            <Link to={'/user-profile/' + data.functionalManager}>{data.functionalManager}</Link>
          </div>
        )}
        {data.manager && (
          <div className={styles.property}>
            <label>Структурный руководитель:</label>
            <Link to={'/user-profile/' + data.manager}>{data.manager}</Link>
          </div>
        )}
      </div>
      <div>
        <div className={styles.property}>
          <label>Дата рождения:</label>
          <label>{formatDate(data.birthDate, { format: 'textDateWithoutYear' })}</label>
        </div>
        <div className={styles.property}>
          <label>В компании с:</label>
          <label>{formatDate(data.startDate)}</label>
        </div>
      </div>
      <div>
        <div className={styles.property}>
          <label>{location}</label>
        </div>
      </div>
    </div>
  )
}

export default PersonCard
