import React, { FC, useMemo } from 'react'

import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { formatDate } from '@helpers/date'

import TaskForm from '@library/widgets/task/TaskForm'

import { Task } from '@services/models/task'

import { $modal } from '@stores'

type IProps = {
  linkedTasks: Array<Task>
}

const HealthCheckFormTasksTab: FC<IProps> = ({ linkedTasks = [] }) => {
  const preparedList = useMemo(() => {
    return _.orderBy(linkedTasks, 'changeDate', 'desc')
  }, [linkedTasks])

  const headers = [
    { key: 'code', header: 'Код', isSortable: true },
    { key: 'dueTo', header: 'Выполнить до', isSortable: true },
    { key: 'theme', header: 'Суть задачи' },
    { key: 'performer', header: 'Исполнитель' },
  ]

  function handleOpen(taskId: string) {
    $modal.add(TaskForm, { taskId })
  }

  const rows = linkedTasks.map((x: any) => ({
    id: x.id,
    code: x.code,
    dueTo: formatDate(x.dueTo),
    performer: x.performer.nickName,
    theme: x.theme,
  }))
  return (
    <div className="mt-20">
      <DataTable headers={headers} rows={rows} isSortable={false}>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps, getToolbarProps }) => {
          return (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })} onClick={() => {}}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => (
                        <TableCell
                          key={cell.id}
                          onClick={() => {
                            handleOpen(row.id)
                          }}
                        >
                          {cell.value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }}
      </DataTable>
    </div>
  )
}

export default observer(HealthCheckFormTasksTab)
