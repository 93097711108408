import React, { FC } from 'react'

import { ActionableNotification } from '@carbon/react'

import styles from './AuthError.module.scss'

interface AuthErrorProps {
  msg: string
  onAction: () => void
}

const AuthError: FC<AuthErrorProps> = ({ msg, onAction }) => {
  return (
    <div className={styles.wrapper}>
      <ActionableNotification
        aria-label="closes notification"
        kind="error"
        hideCloseButton
        lowContrast
        actionButtonLabel="Выход"
        closeOnEscape={false}
        onActionButtonClick={onAction}
        statusIconDescription="notification"
        subtitle={msg}
        title="Ошибка аутентикации"
      />
    </div>
  )
}

export default AuthError
