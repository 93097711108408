import React, { useMemo } from 'react'
import { useState } from 'react'

import { Button, FilterableMultiSelect, MultiSelect } from '@carbon/react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import FeedbackListTable from '@library/widgets/feedback/feedbackListTable/FeedbackListTable'
import { usePersonsFilter, useStatusFilter, useTypeFilter } from '@library/widgets/feedback/hooks'
import FeedbackFormModal from '@library/widgets/userFeedback/FeedbackFormModal'

import {
  FeedbackFilter,
  FeedbackFilterStatusEnum,
  FeedbackFilterTypeEnum,
} from '@services/models/notifications'

import { $modal } from '@stores'

import styles from './FeedbackPage.module.scss'

const FeedbackPage = () => {
  const { statuses, setStatuses, optionsStatus } = useStatusFilter()
  const { types, setTypes, optionsType } = useTypeFilter()
  const { mappedPersons, isPersonsLoading } = usePersonsFilter()
  const [persons, setPersons] = useState<typeof mappedPersons>([])

  const filters: FeedbackFilter = useMemo(() => {
    return {
      type: types.map((x) => x.value) as unknown as FeedbackFilterTypeEnum[],
      status: statuses.map((x) => x.value) as unknown as FeedbackFilterStatusEnum[],
      personNickName: persons.map((x) => x.value),
    }
  }, [types, statuses, persons])

  return (
    <>
      <div className={classNames(styles.filters)}>
        <div className={classNames(styles.filtersItem, styles.multiSelect)}>
          <MultiSelect
            titleText="Тип обращения"
            id="types"
            label={!types?.length ? 'Все' : types.map((item) => item.shortName).join(', ')}
            onChange={(x: any) => setTypes(x.selectedItems)}
            initialSelectedItems={types}
            items={optionsType}
            itemToString={(x) => x.shortName}
            size={'lg'}
          />
        </div>
        <div className={classNames(styles.filtersItem, styles.multiSelect)}>
          <MultiSelect
            titleText="Статус"
            id="statuses"
            label={!statuses?.length ? 'Все' : statuses.map((item) => item.shortName).join(', ')}
            onChange={(x: any) => setStatuses(x.selectedItems)}
            initialSelectedItems={statuses}
            items={optionsStatus}
            itemToString={(x) => x.shortName}
            size={'lg'}
          />
        </div>
        {/* @ts-ignore */}
        <FilterableMultiSelect<typeof mappedPersons>
          key={persons.length}
          filterable
          id="persons"
          placeholder={persons?.length ? persons?.map((x) => x.text).join(', ') : ''}
          onChange={(x) => setPersons(x.selectedItems)}
          selectedItems={persons}
          items={mappedPersons}
          itemToString={(x) => x.text}
          titleText="Пользователь"
          size="lg"
          className={classNames(styles.filtersItem, styles.filterableSelect)}
          disabled={isPersonsLoading}
        />
        <div className={classNames(styles.filtersItem, styles.lastButton)}>
          <Button size="lg" onClick={() => $modal.add(FeedbackFormModal)}>
            Создать обращение
          </Button>
        </div>
      </div>
      <FeedbackListTable filters={filters} className={'mt-20'} />
    </>
  )
}

export default observer(FeedbackPage)
