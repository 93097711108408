import React, { FC, useState } from 'react'

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { formErrors, HEALTHCHECK_CAUSE } from '@src/constants'
import { useInnerValue, useLoader } from '@src/hooks'

import { formatQuarter } from '@helpers/date'

import InlineTabFormWrapper from '@library/layouts/tabs/InlineTabFormWrapper'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'
import { showErrorAlert } from '@library/utils/toast'
import AddCommentForm from '@library/widgets/comments/AddCommentForm'
import CommentsTab from '@library/widgets/comments/CommentsTab'
import FilesTab from '@library/widgets/files/FilesTab'
import HistoryTab, { HistoryChange } from '@library/widgets/history/HistoryTab'

import { devPlanService, humanTaskService } from '@services'
import { Task } from '@services/models/task'

import { $loader, $user } from '@stores'

import styles from './HealthCheckFormTabs.module.scss'
import HealthCheckFormTasksTab from './HealthCheckFormTasksTab'

type IProps = {
  healthCheckId: string
  history: any[]
  comments: any[]
}
const HC_ADD_COMMENT_FORM = 'hc-add-comment-form'

const HealthCheckFormTabs: FC<IProps> = ({ history, healthCheckId, comments: initialComments }) => {
  const [comments, setComments] = useInnerValue<Array<any>>(initialComments)
  const [linkedTasks, setLinkedTasks] = useState<Array<Task>>([])

  const areLinkedTasksLoading = useLoader(async () => {
    const response = await humanTaskService.fetchTasks({
      causeType: HEALTHCHECK_CAUSE,
      causeObjectId: healthCheckId,
    })
    if (response.isSuccess) {
      setLinkedTasks(response.data?.data || [])
    }
  })

  const [selectedIndex, setSelectedIndex] = useState(0)

  const onCommentAdd = $loader.registerHandler(HC_ADD_COMMENT_FORM, async (data) => {
    const resAdd = await devPlanService.addHcComment({
      id: healthCheckId,
      commentText: data.comment,
    })

    if (resAdd.isSuccess) {
      setComments((comments) => [
        {
          author: $user.loggedInUser.preferredUsername,
          commentDate: new Date(),
          commentText: data.comment,
        },
        ...comments,
      ])
    } else {
      showErrorAlert(formErrors.somethingWentWrong)
    }
  })

  return (
    <Tabs defaultSelectedIndex={selectedIndex} onChange={(x) => setSelectedIndex(x.selectedIndex)}>
      <TabList
        aria-label="list-of-comments-and-history"
        contained
        fullWidth
        className={styles.tabs}
      >
        <Tab>Комментарии ({comments.length})</Tab>
        <Tab>История изменений ({history.length})</Tab>
        <Tab>Связанные задачи ({linkedTasks.length})</Tab>
        <Tab>Файлы</Tab>
      </TabList>
      <InlineTabFormWrapper className={'mt-10'}>
        {selectedIndex === 0 && (
          <AddCommentForm formId={HC_ADD_COMMENT_FORM} onCommentAdd={onCommentAdd} />
        )}
      </InlineTabFormWrapper>
      <TabPanels>
        {selectedIndex === 0 && (
          <TabPanel className="p-0">
            <CommentsTab comments={comments} />
          </TabPanel>
        )}
        {selectedIndex === 1 && (
          <TabPanel className="p-0">
            <HistoryTab
              data={history}
              prepareChanges={(changes) => {
                const fieldsMapping: Record<string, string> = {
                  department: 'Подразделение',
                  reason: 'Причина проведения',
                  startDate: 'За период',
                  endDate: 'За период',
                  link: 'Ссылка',
                  linkdescription: 'Заголовок ссылки',
                  generalState: 'Общее состояние команды',
                  managerInteraction: 'Взаимодействие с руководителем',
                  teamInteraction: 'Взаимодействие в команде',
                  communications: 'Внутренние коммуникации',
                  evolution: 'Развитие',
                  finance: 'Финансовая часть',
                  performance: 'Производительность',
                  balance: 'Баланс',
                }
                changes = _.sortBy(changes, (x) => Object.keys(fieldsMapping).indexOf(x.attribute!))

                let result: HistoryChange[] = []
                changes.forEach((x) => {
                  const tmp: HistoryChange = {
                    attribute: x.attribute!,
                    operation: x.operation!,
                    label: fieldsMapping[x.attribute!] || x.attribute!,
                    items: [{ oldValue: x.oldValue!, newValue: x.newValue! }],
                  }

                  if (x.attribute === 'startDate') {
                    tmp.items = [
                      {
                        oldValue: formatQuarter(x.oldValue!),
                        newValue: formatQuarter(x.newValue!),
                      },
                    ]
                  } else if (x.attribute === 'endDate') {
                    return
                  }

                  result.push(tmp)
                })
                return result
              }}
            />
          </TabPanel>
        )}
        {selectedIndex === 2 && (
          <TabPanel className="p-0">
            <WithLoaderWrapper isLoading={areLinkedTasksLoading}>
              <HealthCheckFormTasksTab linkedTasks={linkedTasks} />
            </WithLoaderWrapper>
          </TabPanel>
        )}
        {selectedIndex === 3 && (
          <TabPanel className="p-0">
            <FilesTab healthCheckId={healthCheckId} />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  )
}

export default observer(HealthCheckFormTabs)
