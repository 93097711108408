import axios from 'axios'

export interface AppConfig {
  env: string
  useOidc: boolean
  oidcConfig: any //TODO specify type
  authServiceUrl: string
  permissionsServiceUrl: string
  personServiceUrl: string
  catalogServiceUrl: string
  dashboardServiceUrl: string
  devPlanServiceUrl: string
  taskServiceUrl: string
  notificationsServiceUrl: string
  loaded: boolean
  aidayUrl: string
}
let instance: AppConfig = {
  env: '',
  useOidc: false,
  oidcConfig: undefined,
  authServiceUrl: '',
  permissionsServiceUrl: '',
  personServiceUrl: '',
  catalogServiceUrl: '',
  dashboardServiceUrl: '',
  devPlanServiceUrl: '',
  taskServiceUrl: '',
  notificationsServiceUrl: '',
  loaded: false,
  aidayUrl: '',
}

export const loadConfig = async () => {
  //not using apiRequest to avoid any dependency issues
  return axios.get('/config.json').then((response) => {
    instance = response.data
    instance.loaded = true
    return instance
  })
}
export const getConfig = () => {
  return instance
}
