import { makeAutoObservable, runInAction } from 'mobx'

import { EMPLOYEE, HRDEV, PermissionsListType, SUPERUSER } from '@src/constants'

import { localStore } from '@helpers/other'
import { isEqualLogin } from '@helpers/text.js'

import { permissionsService, personService } from '@services'
import { UserPermissions } from '@services/models/userPermissions'

import { $root as rootStore } from '@stores'

export interface ILoggedInUser {
  name: string
  preferredUsername: string
  email: string

  [key: string]: any
}

class UserStore {
  $root

  constructor(store: typeof rootStore) {
    makeAutoObservable(this)
    this.$root = store
  }

  loggedInUser = {} as ILoggedInUser
  departmentId: string | undefined = undefined
  role = localStore.get('role', '')
  roleGroup = localStore.get('roleGroup', '')
  permissions: PermissionsListType[] = localStore.get('permissions', [])
  availableRoles: UserPermissions[] = localStore.get('availableRoles', [])

  SET_LOGGED_IN_USER(value: any) {
    //TODO fix type
    this.loggedInUser = {
      name: value.name,
      preferredUsername: value.preferred_username,
      email: value.email,
    }
  }

  async fetchPermissions() {
    const response = await permissionsService.fetch()

    if (response.isSuccess && response.data?.data) {
      const data = response.data?.data as UserPermissions[]

      const uniqueRoleGroupsData =
        data?.length > 1
          ? Object.values(
              data.reduce(
                (acc, item) => {
                  const userRoleGroup: string = item?.userRoleGroup ?? ''
                  const permissions: string[] = item.permissions ?? []
                  if (userRoleGroup) {
                    if (!acc[userRoleGroup]) {
                      acc[userRoleGroup] = { ...item }
                    } else {
                      acc[userRoleGroup].permissions = [
                        ...new Set([...acc[userRoleGroup].permissions, ...permissions]),
                      ]
                    }
                  }
                  return acc
                },
                {} as Record<string, any>,
              ),
            )
          : data

      const roleEntry =
        uniqueRoleGroupsData.find((x) => x.userRole === this.role) || uniqueRoleGroupsData[0]
      this.setUserData(roleEntry.userRole, roleEntry.permissions, roleEntry.userRoleGroup)

      this.availableRoles = uniqueRoleGroupsData
      localStore.set('availableRoles', uniqueRoleGroupsData)
    }
  }

  setUserData(role: string, permissions: PermissionsListType[], roleGroup: string) {
    this.role = role
    localStore.set('role', role)

    this.permissions = permissions
    localStore.set('permissions', permissions)

    const roleOrGroup = roleGroup ?? role
    this.roleGroup = roleOrGroup
    localStore.set('roleGroup', roleOrGroup)
  }

  async fetchPerson() {
    const response = await personService.fetchOne(this.loggedInUser.preferredUsername)

    if (response.isSuccess && response.data[0]) {
      const person = response.data[0]
      this.departmentId = person.department?.departmentId
      if (this.loggedInUser.preferredUsername !== person.nickName) {
        //имя пользователя case sensitive
        this.loggedInUser.preferredUsername = person.nickName
      }
    }
  }

  get isPermissionsLoaded() {
    return !!this.permissions.length
  }

  get isSuperUser() {
    return this.role === SUPERUSER
  }

  get isHrDev() {
    return this.role === HRDEV
  }

  get isEmployee() {
    return this.role === EMPLOYEE
  }

  get manager() {
    return this.hasPerm('PERM_MYDEPARTMENTS_MANAGER_READ')
      ? this.loggedInUser.preferredUsername
      : undefined
  }

  get nickname() {
    return this.loggedInUser.preferredUsername
  }

  hasPerm(permKey: PermissionsListType | PermissionsListType[]) {
    if (_.isArray(permKey)) {
      return permKey.some((x) => this.permissions.includes(x))
    } else {
      return this.permissions.includes(permKey)
    }
  }

  canScheduleTask(performer?: string) {
    const isTaskScheduler = this.hasPerm('PERM_TASK_DUETO_EDIT')
    const isTaskPerformer =
      !performer || isEqualLogin(performer, this.loggedInUser.preferredUsername)

    return isTaskScheduler || isTaskPerformer
  }

  canEditTask(author?: string) {
    const isAdmin = this.hasPerm('PERM_TASK_EDIT')
    const isCurrentAuthor =
      this.hasPerm('PERM_TASK_CURRENTAUTHOR_EDIT') &&
      (!author || isEqualLogin(author, this.loggedInUser.preferredUsername))

    return isAdmin || isCurrentAuthor
  }
}

export default UserStore
