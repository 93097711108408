import _ from 'lodash'

declare global {
  interface Console {
    js: (...args: any[]) => void
  }
}

function deepToJS(value: any): any {
  if (_.isArray(value)) {
    value = value.map((x) => deepToJS(x))
  } else if (_.isMap(value)) {
    const tmp: any[] = []
    value.forEach((v, k) => tmp.push({ mapId: k, value: deepToJS(v) }))
    return tmp
  } else if (_.isObject(value)) {
    return _.mapValues(value, (x) => deepToJS(x))
  }

  return value
}

console.js = function consoleJsCustom(...args: any[]) {
  console.log(...deepToJS(args))
}
