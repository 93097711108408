import React, { ReactNode } from 'react'

import classNames from 'classnames'

import styles from './ModalHeader.module.scss'

interface IProps {
  children: ReactNode
  className?: string
}

const ModalHeader: React.FC<IProps> = ({ children, className }) => {
  return <div className={classNames('modal-header', styles.modalHeader, className)}>{children}</div>
}

export default ModalHeader
