/* tslint:disable */
/* eslint-disable */
/**
 * notification
 * Отправка уведомлений пользователям
 *
 * OpenAPI spec version: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

 /**
 * 
 *
 * @export
 * @interface HCMNotification
 */
export interface HCMNotification {

    /**
     * @type {string}
     * @memberof HCMNotification
     */
    id?: string;

    /**
     * @type {string}
     * @memberof HCMNotification
     */
    text?: string;

    /**
     * @type {number}
     * @memberof HCMNotification
     */
    status?: HCMNotificationStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum HCMNotificationStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

