import React, { FC } from 'react'

import { observer } from 'mobx-react-lite'

import Title from '@library/ui/title/Title'

type IProps = {
  title?: string
  message?: string
}

const ErrorPage: FC<IProps> = ({ title = '404', message = 'Страница отсутствует' }) => {
  return (
    <>
      <Title size="h1">{title}</Title>
      <p>{message}</p>
    </>
  )
}

export default observer(ErrorPage)
