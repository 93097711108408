import { useEffect } from 'react'

export const useStopInitialLoading = (condition: boolean) => {
  useEffect(() => {
    if (condition) {
      const spinner = document.querySelector('#index-spinner')
      const styles = document.querySelector('#index-spinner-styles')

      if (spinner && styles) {
        spinner?.classList.add('index-spinner--hide')

        setTimeout(() => {
          spinner?.remove()
          styles?.remove()
        }, 300)
      }
    }
  }, [condition])
}
