import React, { ReactNode } from 'react'

import classNames from 'classnames'

import styles from './ModalFooter.module.scss'

interface IProps {
  children: ReactNode
  className?: string
}

const ModalFooter: React.FC<IProps> = ({ children, className }) => {
  return <div className={classNames('modal-footer', styles.modalFooter, className)}>{children}</div>
}

export default ModalFooter
