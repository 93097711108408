import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useController, UseControllerProps, useWatch } from 'react-hook-form'

import { Select, SelectItem } from '@carbon/react'

import { COMPETENCE_CAUSE } from '@src/constants'

import { formatPeriod } from '@helpers/date'

import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { devPlanService } from '@services'
import { SkillReview } from '@services/models/health-check'

import { $user } from '@stores'

interface IProps extends UseControllerProps {
  labelText: ReactNode
  readOnly: boolean
}

const SkillReviewDropdown = (props: IProps) => {
  const [skillReviews, setSkillReviews] = useState<SkillReview[]>([])
  const [loaded, setLoaded] = useState(false)

  const {
    field,
    fieldState: { invalid, error },
    formState: { defaultValues },
  } = useController(props)

  const values = useWatch({
    control: props.control,
  })

  async function loadSkillReviews(person: string) {
    let resultList: SkillReview[] = []
    const startDate = $user.hasPerm('PERM_SKILL_REVIEW_MANAGER_READ') ? '2000-01-01' : undefined
    setLoaded(false)
    const response = await devPlanService.fetchSkillReview({ person, startDate })

    if (response.isSuccess && response.data?.data) {
      resultList = response.data?.data
      const found = resultList.find((x) => x.reviewToken === field.value.causeObjectId)

      if (resultList.length && !found) {
        field.onChange(getValue(resultList, resultList[0].reviewToken!))
      }
      setSkillReviews(resultList)
    }

    setLoaded(true)
  }

  useEffect(() => {
    if (values.personObject.nickName && values.taskCause.causeType === COMPETENCE_CAUSE) {
      loadSkillReviews(values.personObject.nickName)
    }
  }, [values.taskCause.causeType])

  function getTitle(sr: SkillReview) {
    return `${formatPeriod(sr?.startDate, sr?.endDate)}/${sr?.campaignName ?? 'без имени'}`
  }

  function getValue(list: SkillReview[], token: string) {
    const skillReview = list.find((x) => x.reviewToken === token)

    return {
      causeObjectId: token,
      causeText: getTitle(skillReview!),
      causeType: COMPETENCE_CAUSE,
      causeObjectLink: skillReview?.skillUrl,
    }
  }

  const onChange = (e: any) => {
    const token = e.target.value
    field.onChange(getValue(skillReviews, token))
  }
  const options = useMemo(() => {
    if (skillReviews?.length > 0) {
      return skillReviews.map((sr) => ({
        text: getTitle(sr),
        value: sr.reviewToken,
      }))
    } else {
      setLoaded(true)
      return [
        {
          text: 'нет оценок по сотруднику',
          value: '',
          disabled: true,
        },
      ]
    }
  }, [skillReviews])

  return (
    <WithLoaderWrapper isLoading={!loaded} size="small">
      <Select
        id={field.name}
        labelText={props.labelText}
        invalid={invalid}
        invalidText={error?.message}
        readOnly={props.readOnly}
        name={field.name}
        onChange={onChange}
        onBlur={field.onBlur}
        value={field.value.causeObjectId}
        ref={field.ref}
      >
        {options.map((x: any) => (
          <SelectItem key={x.value} text={x.text} value={x.value} disabled={x.disabled ?? false} />
        ))}
      </Select>
    </WithLoaderWrapper>
  )
}

export default SkillReviewDropdown
