import React from 'react'
import { Outlet } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import PageTabs, { PageTabItem } from '@library/ui/pageTabs/PageTabs'

const routesConfig: PageTabItem[] = [
  {
    label: 'Роли пользователей',
    route: 'roles',
    // condition: () => true,
  },
]

export function getAvailableAdminTabs() {
  return routesConfig.filter((x) => !x.condition || x.condition())
}

const AdministrationPage = () => {
  return (
    <>
      {/*<PageTabs tabs={getAvailableAdminTabs()} />*/}
      <Outlet />
    </>
  )
}

export default observer(AdministrationPage)
