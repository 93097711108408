import React, { FC } from 'react'

import classNames from 'classnames'

import Title from '@library/ui/title/Title'

import { ExtendedPersonCardForList } from '../../PersonHierarchyModal.types'
import styles from './ChildCards.module.scss'
import ChildCardsItem from './ChildCardsItem'

type IProps = {
  title: string
  data: ExtendedPersonCardForList[]
  onChangePerson: (nickName: string) => void
  className?: string
}

const ChildCards: FC<IProps> = ({ title, data, onChangePerson, className }) => {
  if (!data?.length) {
    return <></>
  }

  return (
    <div className={classNames(className)}>
      <Title size={'h4'} className={'mb-10'}>
        {title}
        <span className={classNames('text-muted', 'ml-5')}>({data.length})</span>
      </Title>
      <div className={styles.childCards}>
        {data.map((x) => (
          <ChildCardsItem key={x.id} data={x} onChangePerson={onChangePerson} />
        ))}
      </div>
    </div>
  )
}

export default ChildCards
