import { useState } from 'react'

import { useLoader } from '@src/hooks'

import { catalogService } from '@services'

type OptionItem = {
  key: string
  text: string
  value: string
}

export const useHRPPFilter = () => {
  const [hrppsOptions, setHrppsOptions] = useState<OptionItem[]>([])

  const isHRPPLoading = useLoader(async () => {
    const response = await catalogService.getHRPPList()

    if (response.isSuccess && response.data?.data) {
      const sorted = _.orderBy(response.data?.data, [], ['asc'])
      const mapped = sorted?.map((user, index) => ({
        key: [index, user].join('_'),
        text: user,
        value: user,
      }))
      setHrppsOptions(mapped)
    }
  }, [])

  return {
    hrppsOptions,
    isHRPPLoading,
  }
}
