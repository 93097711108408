import { useMemo, useState } from 'react'

import { toJS } from 'mobx'

import { $catalog } from '@stores'
import { ICatalogTaskStatus } from '@stores/catalogStore'

export const useStatusFilter = () => {
  const optionsStatus = useMemo(() => toJS($catalog.taskStatus), [])
  const [statuses, setStatuses] = useState<ICatalogTaskStatus[]>(optionsStatus)

  return {
    optionsStatus,
    statuses,
    setStatuses,
  }
}
