import React, { FC } from 'react'

import {
  Code,
  Doc,
  DocumentAudio,
  DocumentUnknown,
  DocumentVideo,
  Gif,
  Html,
  Jpg,
  Json,
  Pdf,
  Png,
  Ppt,
  Svg,
  Txt,
  Xls,
  Zip,
} from '@carbon/icons-react'

import { getFileExtension } from '@helpers/text.js'

type IProps = {
  type?: string
  fileName?: string
  size?: number
}

const FileTypeIcon: FC<IProps> = ({ type, fileName, size = 24 }) => {
  if (!type && fileName) {
    type = getFileExtension(fileName)
  }

  switch (type) {
    case 'svg':
      return <Svg size={size} />
    case 'png':
      return <Png size={size} />
    case 'gif':
      return <Gif size={size} />
    case 'jpg':
    case 'jpeg':
      return <Jpg size={size} />
    case 'wepb':
      return <Jpg size={size} />
    case 'pdf':
      return <Pdf size={size} />
    case 'doc':
    case 'docx':
      return <Doc size={size} />
    case 'xls':
    case 'xlsx':
      return <Xls size={size} />
    case 'mp3':
    case 'wav':
    case 'ogg':
      return <DocumentAudio size={size} />
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'wmv':
    case 'flv':
    case 'mkv':
      return <DocumentVideo size={size} />
    case 'zip':
    case 'rar':
    case '7z':
    case 'tar':
    case 'gz':
      return <Zip size={size} />
    case 'txt':
      return <Txt size={size} />
    case 'ppt':
    case 'pptx':
      return <Ppt size={size} />
    case 'json':
      return <Json size={size} />
    case 'html':
      return <Html size={size} />
    case 'js':
    case 'css':
    case 'xml':
      return <Code size={size} />
    default:
      return <DocumentUnknown size={size} />
  }
}

export default FileTypeIcon
