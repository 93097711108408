import { apiRequest } from '@services/api'
import { AppConfig } from '@services/config'
import {
  Onboarding,
  OnboardingFiltered,
  OnboardingsFilter,
  OnboardingsListFiltered,
  Paging,
  PersonAbsence,
  PersonCard,
  PersonsList,
  TasksData,
} from '@services/models/person'

class PersonService {
  baseUrl: string

  constructor(config: AppConfig) {
    this.baseUrl = config.personServiceUrl
  }

  async fetch(
    manager: string | undefined,
    departments: string | string[] | undefined,
    functionalManager?: string | undefined,
    limit: number = 1000,
    offset: number = 0,
    sortBy?: string,
    sortOrder?: string,
  ) {
    return apiRequest<PersonsList>({
      url: this.baseUrl + '/persons',
      data: {
        departments,
        manager,
        offset,
        limit,
        sortBy,
        sortOrder,
        functionalManager,
      },
      method: 'GET',
      cacheDuration: 3 * 60000,
    })
  }

  async fetchOne(login: string, data?: { expand: string }) {
    return apiRequest<PersonCard[]>({
      url: this.baseUrl + '/persons/' + login,
      data,
      method: 'GET',
    })
  }

  async fetchAbsence(login: string, params?: {}) {
    return apiRequest<{ personAbsence?: PersonAbsence[]; paging?: Paging }>({
      url: this.baseUrl + '/persons/' + login + '/absence',
      data: params,
      method: 'GET',
    })
  }

  async fetchOnboardings(login: string) {
    return apiRequest({
      url: this.baseUrl + '/onboardings',
      data: {
        person: login,
      },
      method: 'GET',
    })
  }

  async fetchOnboardingsForAnalytics(
    filters: OnboardingsFilter,
    limit: number,
    offset: number,
    sortBy?: Extract<
      keyof OnboardingFiltered,
      | 'personNickName'
      | 'departmentName'
      | 'startDate'
      | 'endDate'
      | 'dueTo'
      | 'hrpp'
      | 'mentor'
      | 'planLink'
      | 'author'
    >,
    sortOrder?: 'asc' | 'desc',
  ) {
    return apiRequest<OnboardingsListFiltered>({
      method: 'POST',
      url: this.baseUrl + '/onboardings_filtered',
      data: filters,
      params: {
        offset,
        limit,
        sortBy,
        sortOrder,
      },
    })
  }

  async fetchOneOnboarding(id: string) {
    return apiRequest({
      url: this.baseUrl + '/onboardings/' + id,
      method: 'GET',
    })
  }

  async createOnboarding(params: Onboarding) {
    return apiRequest({
      method: 'POST',
      url: this.baseUrl + `/onboardings`,
      data: params,
    })
  }

  async updateOnboarding(params: Onboarding) {
    return apiRequest({
      method: 'PUT',
      url: this.baseUrl + `/onboardings/${params.id}`,
      data: params,
    })
  }

  async deleteOnboarding(id: string) {
    return apiRequest({
      method: 'DELETE',
      url: this.baseUrl + `/onboardings/${id}`,
    })
  }

  async getOnboardingComments(id: number | string) {
    return apiRequest({
      method: 'GET',
      url: this.baseUrl + `/onboardings/${id}/comments`,
    })
  }

  async addOnboardingComment(id: number | string, params: { commentText: string }) {
    return apiRequest({
      method: 'POST',
      url: this.baseUrl + `/onboardings/${id}/comments`,
      data: params,
    })
  }

  async get1CTaskCount(login: string) {
    return apiRequest<TasksData>({
      method: 'GET',
      url: this.baseUrl + `/1Ctasks/${login}/count`,
    })
  }
}

export default PersonService
