import { useMemo, useState } from 'react'

import { toJS } from 'mobx'

import { $catalog } from '@stores'
import { IFeedbackType } from '@stores/catalogStore'

export const useTypeFilter = () => {
  const optionsType = useMemo(() => toJS($catalog.feedbackType), [$catalog.feedbackType])
  const [types, setTypes] = useState<IFeedbackType[]>([])

  return {
    types,
    setTypes,
    optionsType,
  }
}
