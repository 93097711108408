import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Tab, TabList, Tabs } from '@carbon/react'
import classNames from 'classnames'

import styles from './PageTabs.module.scss'

export type PageTabItem = {
  label: string | ReactNode
  route: string
  condition?: () => boolean
}

type IProps = {
  tabs: PageTabItem[]
  className?: string
}

const PageTabs: FC<IProps> = ({ tabs, className }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const nav = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const parts = location.pathname.split('/').filter((x) => !!x)

    let idx = 0
    for (const i in parts) {
      idx = tabs.findIndex((x) => x.route === parts[i])
      if (idx >= 0) break
    }

    if (idx >= 0) {
      setSelectedIndex(idx)
    }
  }, [location])

  const onTabSelection = (selection: any) => {
    if (tabs[selection.selectedIndex]) {
      nav(tabs[selection.selectedIndex].route)
    }
  }

  return (
    <Tabs selectedIndex={selectedIndex} onChange={onTabSelection}>
      <TabList aria-label="page-submenu" contained className={classNames(styles.tabs, className)}>
        {tabs.map((item) => (
          <Tab key={item.route}>{item.label}</Tab>
        ))}
      </TabList>
    </Tabs>
  )
}

export default PageTabs
