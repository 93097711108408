import React, { FC } from 'react'

import { observer } from 'mobx-react-lite'

import Title from '@library/ui/title/Title'

type IProps = {
  //
}
const ProfilePage: FC<IProps> = () => {
  return (
    <>
      <Title size="h2">Профиль</Title>
    </>
  )
}

export default observer(ProfilePage)
