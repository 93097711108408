import React, { createContext, FC, ReactNode, useContext } from 'react'
import { AuthProvider, useAuth } from 'react-oidc-context'

import { hcmUserManager, tokenService } from '@services'
import { getConfig } from '@services/config'

type IProps = {
  children: ReactNode
}
const onSigninCallback = (_user: any): void => {
  const parts = window.location.href.split('?')

  const params = new URLSearchParams(parts[1])
  params.delete('code')
  params.delete('state')
  params.delete('iss')

  const pathname = params.size
    ? `${window.location.pathname}?${params.toString()}`
    : window.location.pathname

  window.history.replaceState({}, document.title, pathname)
}
const Context = createContext({
  logout: () => {},
})

export const useAppAuth = () => {
  const config = useContext(Context)

  return config
}
const OidcAuthProvider: FC<IProps> = ({ children }) => {
  const auth = useAuth()

  async function logout() {
    auth.signoutRedirect()
  }

  const config = {
    logout,
  }
  return <Context.Provider value={config}>{children}</Context.Provider>
}
const LocalAuthProvider: FC<IProps> = ({ children }) => {
  const config = {
    logout: () => {
      tokenService.clearToken()
    },
  }
  return <Context.Provider value={config}>{children}</Context.Provider>
}

const AppAuthProvider: FC<IProps> = ({ children }) => {
  const appConfig = getConfig()
  return appConfig.useOidc ? (
    <AuthProvider userManager={hcmUserManager} onSigninCallback={onSigninCallback}>
      <OidcAuthProvider>{children}</OidcAuthProvider>
    </AuthProvider>
  ) : (
    <LocalAuthProvider>{children}</LocalAuthProvider>
  )
}
export default AppAuthProvider
