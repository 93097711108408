import React, { useMemo, useState } from 'react'

import {
  Button,
  DataTable,
  OverflowMenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from '@carbon/react'
//@ts-ignore
import { OverflowMenu } from '@carbon/react/lib/components/OverflowMenu'
import classNames from 'classnames'

import { formErrors } from '@src/constants'
import { useLoader } from '@src/hooks'

import { formatDate } from '@helpers/date'

import LayoutLkPageWrapper from '@library/layouts/LayoutLkPageWrapper'
import { showErrorAlert } from '@library/utils/toast'
import PersonalEvolutionPlanFormModal from '@library/widgets/personalEvolutionPlan/PersonalEvolutionPlanFormModal'

import { devPlanService } from '@services'
import { IPR } from '@services/models/health-check'

import { $cabinet, $modal, $user } from '@stores'

const PersonalEvaluationPlanPage = () => {
  const [personalEvaluationPlanList, setPersonalEvaluationPlanList] = useState<IPR[]>([])

  async function loadData() {
    const response = await devPlanService.fetchPersonalEvolutionPlans({
      person: $cabinet.selectedLogin,
    })

    if (response.isSuccess) {
      const data = response.data?.data || []
      setPersonalEvaluationPlanList(data)
    }
  }

  const isLoading = useLoader(async () => {
    loadData()
  })

  function afterSubmit() {
    loadData()
  }

  function handleOpen(id?: string) {
    $modal.add(PersonalEvolutionPlanFormModal, { id, afterSubmit })
  }

  async function handleDelete(id: string) {
    const response = await devPlanService.deletPersonalEvolutionPlan(id)

    if (response.isSuccess) {
      loadData()
    } else {
      const msg =
        response.response?.status === 403 ? formErrors.forbidden : formErrors.somethingWentWrong
      showErrorAlert(msg)
    }
  }

  const sorted = useMemo<IPR[]>(() => {
    return _.orderBy(personalEvaluationPlanList, 'startDate', 'desc')
  }, [personalEvaluationPlanList])

  const headers = [
    { key: 'period', header: 'Период' },
    { key: 'fileType', header: 'Формат' },
    { key: 'link', header: 'Ссылка' },
  ]

  const rows = sorted.map((x: any, index) => ({
    id: x.id,
    period: `${formatDate(x.startDate)} - ${formatDate(x.endDate)}`,
    fileType: x.fileType,
    link: x.link,
  }))

  return (
    <LayoutLkPageWrapper isLoading={false}>
      <DataTable headers={headers} rows={rows} isSortable={false}>
        {({
          rows,
          headers,
          getTableProps,
          getTableContainerProps,
          getHeaderProps,
          getRowProps,
          getToolbarProps,
        }) => {
          return (
            <TableContainer {...getTableContainerProps()}>
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  {$user.hasPerm('PERM_IPR_ADD') && (
                    <Button
                      onClick={() => {
                        handleOpen()
                      }}
                      kind="primary"
                    >
                      Добавить ИПР
                    </Button>
                  )}
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })} onClick={() => {}}>
                        {header.header}
                      </TableHeader>
                    ))}
                    <TableHeader className={classNames('action-column')}></TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow {...getRowProps({ row })}>
                      {row.cells.map((cell) => (
                        <TableCell
                          key={cell.id}
                          onClick={() => {
                            handleOpen(row.id)
                          }}
                        >
                          {cell.value}
                        </TableCell>
                      ))}
                      <TableCell key={'action:' + row.id}>
                        <OverflowMenu aria-label="overflow-menu" align="bottom">
                          <OverflowMenuItem
                            itemText="Удалить"
                            disabled={!$user.hasPerm('PERM_IPR_DELETE')}
                            onClick={() => {
                              handleDelete(row.id)
                            }}
                          />
                        </OverflowMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }}
      </DataTable>
    </LayoutLkPageWrapper>
  )
}

export default PersonalEvaluationPlanPage
