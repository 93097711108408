import React from 'react'

import { EMPLOYEE, HRDEV } from '@src/constants'

import { $cabinet } from '@stores'

import AbsencePage from '@pages/cabinet/absence/AbsencePage'
import EquipmentPage from '@pages/cabinet/equipment/EquipmentPage'
import GoalsPerformancePage from '@pages/cabinet/goalsPerformance/GoalsPerformancePage'
import OnboardingsPage from '@pages/cabinet/onboardings/OnboardingsPage'
import PersonalEvolutionPlanPage from '@pages/cabinet/personalEvolutionPlan/PersonalEvolutionPlanPage'
import SkillReviewsPage from '@pages/cabinet/skillReviews/SkillReviewsPage'
import TasksPage from '@pages/cabinet/tasks/TasksPage'
import TransitionsPage from '@pages/cabinet/transitions/TransitionsPage'

export const nestedRoutes = (role: string) => {
  switch (role) {
    case EMPLOYEE:
      return [
        {
          label: 'Отсутствия',
          route: 'absence',
          element: <AbsencePage />,
          condition: () => $cabinet.canSeeAbsence,
        },
        {
          label: 'Оборудование',
          route: 'equipment',
          element: <EquipmentPage />,
          condition: () => $cabinet.canSeeEquipment,
        },
        {
          label: 'История перемещений',
          route: 'transitions',
          element: <TransitionsPage />,
          condition: () => $cabinet.canSeeTransitions,
        },
        {
          label: 'ИПР',
          route: 'ipr',
          element: <PersonalEvolutionPlanPage />,
          condition: () => $cabinet.canSeePersonalEvolution,
        },
        {
          label: 'Оценка компетенций',
          route: 'competence',
          element: <SkillReviewsPage />,
          condition: () => $cabinet.canSeeSkillReview,
        },
        {
          label: 'Адаптация',
          route: 'onboardings',
          element: <OnboardingsPage />,
          condition: () => $cabinet.canSeeOnboardings,
        },
        {
          label: 'Цели и результативность',
          route: 'goals',
          element: <GoalsPerformancePage />,
          condition: () => true,
        },
        {
          label: 'Задачи',
          route: 'tasks',
          element: <TasksPage />,
          condition: () => $cabinet.canSeeTasks,
        },
      ]

    case HRDEV:
      return [
        {
          label: 'Адаптация',
          route: 'onboardings',
          element: <OnboardingsPage />,
          condition: () => $cabinet.canSeeOnboardings,
        },
        {
          label: 'Цели и результативность',
          route: 'goals',
          element: <GoalsPerformancePage />,
          condition: () => true,
        },
        {
          label: 'Оценка компетенций',
          route: 'competence',
          element: <SkillReviewsPage />,
          condition: () => $cabinet.canSeeSkillReview,
        },
        {
          label: 'ИПР',
          route: 'ipr',
          element: <PersonalEvolutionPlanPage />,
          condition: () => $cabinet.canSeePersonalEvolution,
        },
        {
          label: 'Отсутствия',
          route: 'absence',
          element: <AbsencePage />,
          condition: () => $cabinet.canSeeAbsence,
        },
        {
          label: 'История перемещений',
          route: 'transitions',
          element: <TransitionsPage />,
          condition: () => $cabinet.canSeeTransitions,
        },

        {
          label: 'Оборудование',
          route: 'equipment',
          element: <EquipmentPage />,
          condition: () => $cabinet.canSeeEquipment,
        },
      ]

    default:
      return [
        {
          label: 'Задачи',
          route: 'tasks',
          element: <TasksPage />,
          condition: () => $cabinet.canSeeTasks,
        },

        {
          label: 'Адаптация',
          route: 'onboardings',
          element: <OnboardingsPage />,
          condition: () => $cabinet.canSeeOnboardings,
        },
        {
          label: 'Цели и результативность',
          route: 'goals',
          element: <GoalsPerformancePage />,
          condition: () => true,
        },
        {
          label: 'Оценка компетенций',
          route: 'competence',
          element: <SkillReviewsPage />,
          condition: () => $cabinet.canSeeSkillReview,
        },
        {
          label: 'ИПР',
          route: 'ipr',
          element: <PersonalEvolutionPlanPage />,
          condition: () => $cabinet.canSeePersonalEvolution,
        },
        {
          label: 'Отсутствия',
          route: 'absence',
          element: <AbsencePage />,
          condition: () => $cabinet.canSeeAbsence,
        },
        {
          label: 'История перемещений',
          route: 'transitions',
          element: <TransitionsPage />,
          condition: () => $cabinet.canSeeTransitions,
        },

        {
          label: 'Оборудование',
          route: 'equipment',
          element: <EquipmentPage />,
          condition: () => $cabinet.canSeeEquipment,
        },
      ]
  }
}
