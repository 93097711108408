import React, { FC, ReactNode, useState } from 'react'

import { Edit } from '@carbon/icons-react'
import classNames from 'classnames'

import styles from './FormEditLink.module.scss'

type IProps = {
  value: string
  text?: string
  readOnly?: boolean
  initOpen?: boolean
  children: ReactNode
}

const FormEditLink: FC<IProps> = ({
  value,
  text = '',
  initOpen = false,
  readOnly = false,
  children,
}) => {
  const [isEditing, setIsEditing] = useState(_.isEmpty(value))

  return (
    <>
      {(initOpen || isEditing) && !readOnly ? (
        children
      ) : (
        <div className={classNames('cds--form-item')}>
          <div className={styles.formLink}>
            {value && (
              <a href={value} target="_blank" rel="noreferrer">
                {text || value}
              </a>
            )}
            {!readOnly && (
              <Edit
                className={styles.formLinkEditIcon}
                title="Редактировать"
                onClick={() => setIsEditing(true)}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default FormEditLink
