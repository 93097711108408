import React, { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, ButtonSet, Column, ComboBox, Row, Select, SelectItem, Stack } from '@carbon/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react-lite'
import { z } from 'zod'

import { formErrors, UNAVAILABLE_ROLE_HCM_ADD } from '@src/constants'

import Title from '@library/ui/title/Title'
import { ModalBase, ModalBody, ModalFooter, ModalHeader } from '@library/utils/modals/index'
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store'
import { showErrorAlert, showSuccessAlert } from '@library/utils/toast'
import DepartmentsModal from '@library/widgets/department/DepartmentsModal'
import { usePersonsFilter } from '@library/widgets/filters/hooks/usePersonsFilter'
import filterStyles from '@library/widgets/filters/styles/Filters.module.scss'

import { catalogService, permissionsService, personService } from '@services'
import { Department } from '@services/models/catalog'
import { UsersRolesListUserRoles } from '@services/models/userPermissions'

import { $catalog, $filter, $loader, $modal, $user } from '@stores'

import styles from './RolesAddFormModal.module.scss'

interface IProps {
  _core: IModalItemCore
}

const schema = z.object({
  login: z.string().min(1, { message: formErrors.required }),
  role: z.string().min(1, { message: formErrors.required }),
  depart: z.array(
    z.object({
      name: z.string(),
      code: z.string(),
    }),
  ),
})

const RolesAddFormModal: FC<IProps> = ({ _core }) => {
  const { mappedPersons, isPersonsLoading } = usePersonsFilter()
  const [userRoles, setUserRoles] = useState<UsersRolesListUserRoles[]>([])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  })
  const values = watch()
  const isCustomManager = useMemo(() => values.role === 'MANAGERCUSTOM', [values.role])

  const rolesOptions = useMemo(() => {
    const availableRoles =
      $catalog?.rolesList?.filter((roleOption) => {
        return (
          !UNAVAILABLE_ROLE_HCM_ADD.some((role) => role === roleOption?.value) &&
          !userRoles?.some((role) => role?.role === roleOption?.value)
        )
      }) || []

    const options = availableRoles?.map((item) => ({ label: item?.shortName, id: item?.value }))
    setValue('role', options[0]?.id)
    return options
  }, [values.login, userRoles])

  const isDisabledSbmt = useMemo(
    () => isCustomManager && $filter.selectedList?.length < 1,
    [isCustomManager, $filter.selectedList],
  )

  useEffect(() => {
    if (values.login) {
      getUserRoles(values.login)
    }
  }, [values.login])

  const getUserRoles = async (user: string) => {
    const response = await permissionsService.fetchUserRoles({
      limit: 100,
      offset: 0,
      user,
    })

    if (response.isSuccess && response.data?.data) {
      if (response.data?.data[0]?.userRoles) {
        setUserRoles(response.data?.data[0]?.userRoles)
      }
    }
  }

  const getAllDepartmens = async () => {
    const response = await catalogService.fetchDepartments(undefined)

    if (response.isSuccess && response.data) {
      $filter.SET_DEPARTMENTS(response.data[0].data)
      $filter.SET_SELECTED_CODES([])
    }
  }

  useEffect(() => {
    if (values.role && isCustomManager) {
      getAllDepartmens()
    }
  }, [isCustomManager])

  useEffect(() => {
    const newDeparts = ($filter.selectedList as Department[])?.map((item) => ({
      name: item?.name,
      code: item?.code,
    }))
    setValue('depart', newDeparts)
  }, [$filter.selectedList])

  const handleOpenDepartmentsModal = () => {
    $modal.add(DepartmentsModal, {
      departmentList: $filter.departments,
      onSelect: (departments: any) => {
        if (departments) {
          $filter.SET_SELECTED_CODES(departments)
        }
      },
    })
  }

  const onSubmit = $loader.registerHandler('roles-add-form', async (data) => {
    if (!$user.hasPerm('PERM_USER_ROLE_ADD')) {
      return showErrorAlert(formErrors.forbidden)
    }

    const departBody = data?.depart
      ? data?.depart?.map((item: { name: string; code: string }) => ({ subDepartment: item?.code }))
      : []

    const body = isCustomManager
      ? {
          roleSource: 'HCM',
          userLogin: data?.login,
          userRole: data?.role,
          subDepartments: departBody,
        }
      : {
          roleSource: 'HCM',
          userLogin: data?.login,
          userRole: data?.role,
        }

    const response = await permissionsService.addUserRole(body)

    if (response.isSuccess) {
      showSuccessAlert('Роль добавлена.')
      _core.hide()
    } else {
      showErrorAlert(formErrors.somethingWentWrong)
    }
  })

  return (
    <ModalBase>
      <ModalHeader>
        <Title size="h2">Назначение роли</Title>
      </ModalHeader>
      <ModalBody isLoading={isPersonsLoading}>
        <form id="roles-form" onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <Stack gap={6}>
            <ComboBox
              /* @ts-ignore */
              id="login"
              items={mappedPersons}
              titleText="Ник"
              itemToString={(item: any) => (item ? item.text : '')}
              onChange={(item: any) => setValue('login', item?.selectedItem?.text)}
              disabled={isPersonsLoading}
              // downshiftProps={{isOpen: true}}
              invalid={!!errors?.login}
              invalidText={<>{errors.login?.message}</>}
              className={filterStyles.comboBox}
            />

            <Select
              id="role"
              labelText="Роль"
              defaultValue={values?.role}
              {...register('role')}
              invalid={!!errors.role}
              invalidText={<>{errors.role?.message}</>}
              disabled={!values.login || rolesOptions?.length < 1}
            >
              {rolesOptions?.length > 0 ? (
                rolesOptions?.map((x) => <SelectItem key={x.id} text={x.label} value={x.id} />)
              ) : (
                <SelectItem key={0} text={'Для пользователя нет доступных ролей'} value={0} />
              )}
            </Select>
            {isCustomManager && (
              <>
                <Column style={{ padding: '0px' }}>
                  <Button size="sm" onClick={handleOpenDepartmentsModal}>
                    Добавить подразделение
                  </Button>
                </Column>
                <div className={styles.departmentList}>
                  <Column className={styles.list}>
                    {$filter.selectedList?.length > 0 ? (
                      $filter.selectedList?.map((item, index) => (
                        <Row className={styles.row} key={'dep-' + index}>
                          <p>{item?.name}</p>
                        </Row>
                      ))
                    ) : (
                      <Row className={styles.info}>
                        <p>Необходимо назначить подразделения для руководителя</p>
                      </Row>
                    )}
                  </Column>
                </div>
              </>
            )}
          </Stack>
        </form>
      </ModalBody>
      {!isPersonsLoading && (
        <ModalFooter>
          <ButtonSet>
            <Button kind="tertiary" onClick={_core.hide}>
              Отмена
            </Button>
            <Button form="roles-form" type="submit" disabled={isDisabledSbmt}>
              Сохранить
            </Button>
          </ButtonSet>
        </ModalFooter>
      )}
    </ModalBase>
  )
}

export default observer(RolesAddFormModal)
