import React from 'react'

import { Column, Row } from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { TaskAnalyticsStatusMetrics } from '@services/models/dashboard'

import styles from '../../../../pages/analytics/task/TaskAnalyticsPage.module.scss'

const TaskStats = ({ data }: { data?: TaskAnalyticsStatusMetrics[] }) => {
  return (
    <Row className={styles.stats}>
      {data?.map((status, index) => {
        const title =
          status?.status === 'TODO'
            ? 'Запланировано'
            : status?.status === 'INPROGRESS'
            ? 'В работе'
            : status?.status === 'DONE'
            ? 'Завершено'
            : ''
        return (
          <Column key={'status' + index}>
            <h3>{title}</h3>
            <span>
              <p>{status?.count} </p>{' '}
              <p className={styles.orange}>{` (${status?.overdueRate}% просрочено)`}</p>
            </span>
          </Column>
        )
      })}
    </Row>
  )
}

export default observer(TaskStats)
