import React, { FC, useCallback, useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { useLoader } from '@src/hooks'

import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'
import { ModalBase, ModalBody, ModalHeader } from '@library/utils/modals'
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store'

import { personService } from '@services'
import { PersonCardForList, PersonCard as PersonCardType } from '@services/models/person'

import { $loader } from '@stores'

import { useExtendedPersonsObj } from './PersonHierarchyModal.hooks'
import styles from './PersonHierarchyModal.module.scss'
import ChildCards from './ui/childCards/ChildCards'
import PersonCard from './ui/personCard/PersonCard'

type IProps = {
  initNickName: string
  personsList: PersonCardForList[]
  _core: IModalItemCore
}

const fetchPersonLoaderName = 'person-hierarchy-load-person'

const PersonHierarchyModal: FC<IProps> = ({
  initNickName,
  personsList: _personsList = [],
  _core,
}) => {
  const [pathToBack, setPathToBack] = useState<string[]>([])
  const [personCard, setPersonCard] = useState<PersonCardType>()
  const [personsList, setPersonsList] = useState<PersonCardForList[]>(_personsList)

  /*
   * Data loading
   */
  const isPersonsListLoading = useLoader(async () => {
    if (personsList.length) return

    const response = await personService.fetch(undefined, undefined)
    if (response.isSuccess && response.data?.data) {
      setPersonsList(response.data.data)
    }
  })

  const handleFetchPerson = $loader.registerHandler(
    fetchPersonLoaderName,
    async (nickName: string) => {
      if (!nickName) return
      const response = await personService.fetchOne(nickName)

      if (response.isSuccess && response.data[0]) {
        setPersonCard(response.data[0])
      } else {
        setPersonCard(undefined)
      }
    },
  )
  useEffect(() => {
    handleFetchPerson(initNickName)
  }, [])

  const isLoading = isPersonsListLoading || $loader.isRunHandler(fetchPersonLoaderName)

  /*
   * Prepared values
   */
  const extendedPersonsObj = useExtendedPersonsObj(personsList)
  const currentPerson = extendedPersonsObj[personCard?.nickName!]

  const handleOnChangePerson = useCallback(
    (nickName: string) => {
      setPathToBack([...pathToBack, personCard?.nickName!].filter((x) => x))
      handleFetchPerson(nickName)
    },
    [pathToBack, personCard],
  )

  const handleOnBack = useCallback(() => {
    if (pathToBack.length) {
      handleFetchPerson(pathToBack[pathToBack.length - 1]).then(() => {
        setPathToBack(pathToBack.slice(0, -1))
      })
    }
  }, [pathToBack])

  return (
    <ModalBase
      size={'lg'}
      centered={false}
      backButton={(pathToBack.length > 0 && !isLoading) || pathToBack.length > 1}
      onBack={handleOnBack}
    >
      <ModalHeader>
        <></>
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        <WithLoaderWrapper isLoading={isLoading} mode={'update'} className={styles.loaderWrapper}>
          {personCard && (
            <PersonCard
              data={personCard}
              extendedPersonsObj={extendedPersonsObj}
              onChangePerson={handleOnChangePerson}
              _core={_core}
            />
          )}
          {currentPerson?.asFunctionalManager.length > 0 && (
            <ChildCards
              title={'Как фактический руководитель'}
              data={currentPerson?.asFunctionalManager.map((x) => extendedPersonsObj[x])}
              onChangePerson={handleOnChangePerson}
              className={'mt-30'}
            />
          )}
          {currentPerson?.asManager.length > 0 && (
            <ChildCards
              title={'Как структурный руководитель'}
              data={currentPerson?.asManager.map((x) => extendedPersonsObj[x])}
              onChangePerson={handleOnChangePerson}
              className={'mt-30'}
            />
          )}
        </WithLoaderWrapper>
      </ModalBody>
    </ModalBase>
  )
}

export default observer(PersonHierarchyModal)
