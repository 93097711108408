import { apiRequest } from '@services/api'
import { AppConfig } from '@services/config'
import {
  HCFilter,
  IPRFilter,
  OnboardingAnalytics,
  OnboardingFilter,
  TaskFilter,
} from '@services/models/dashboard'

class DashboardService {
  baseUrl: string

  constructor(config: AppConfig) {
    this.baseUrl = config.dashboardServiceUrl
  }

  async getPepAnalytics(options: IPRFilter) {
    return apiRequest({
      url: this.baseUrl + '/ipr',
      data: options,
      method: 'POST',
    })
  }

  async getTasksAnalytics(options?: TaskFilter) {
    return apiRequest({
      url: this.baseUrl + '/task',
      data: options,
      method: 'POST',
    })
  }

  async getHCAnalytics(options?: HCFilter) {
    return apiRequest({
      url: this.baseUrl + '/health_check',
      data: options,
      method: 'POST',
    })
  }

  async getHCHeatMap(options?: HCFilter) {
    return apiRequest({
      url: this.baseUrl + '/heat_map',
      data: options,
      method: 'POST',
    })
  }

  async getOnboardingAnalytics(options?: OnboardingFilter) {
    return apiRequest<OnboardingAnalytics[]>({
      url: this.baseUrl + '/onboarding',
      data: options,
      method: 'POST',
    })
  }
}

export default DashboardService
