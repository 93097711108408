import React, { FC, useState } from 'react'

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { formErrors } from '@src/constants'

import InlineTabFormWrapper from '@library/layouts/tabs/InlineTabFormWrapper'
import { showErrorAlert } from '@library/utils/toast'
import CommentsTab from '@library/widgets/comments/CommentsTab'

import { personService } from '@services'
import { Onboarding } from '@services/models/person'

import { $loader } from '@stores'

import AddCommentForm from '../../comments/AddCommentForm'
import styles from './OnboardingTabs.module.scss'

type IProps = {
  item: Onboarding
}

const ADD_COMMENT_FORM = 'onboarding-add-comment-form'

const OnboardingTabs: FC<IProps> = ({ item }) => {
  const [comments, setComments] = useState(item.commentsList || [])

  const [selectedIndex, setSelectedIndex] = useState(0)

  const isDisabled = false

  const onCommentAdd = $loader.registerHandler(ADD_COMMENT_FORM, async (data) => {
    const resAdd = await personService.addOnboardingComment(item.id!, {
      commentText: data.comment,
    })

    if (resAdd.isSuccess) {
      const resUpdate = await personService.getOnboardingComments(item.id!)
      if (resUpdate.isSuccess) {
        setComments(resUpdate.data)
      }
    } else {
      showErrorAlert(formErrors.somethingWentWrong)
    }
  })

  return (
    <Tabs defaultSelectedIndex={selectedIndex} onChange={(x) => setSelectedIndex(x.selectedIndex)}>
      <TabList
        aria-label="list-of-comments-and-history"
        contained
        fullWidth
        className={styles.tabs}
      >
        <Tab>Комментарии ({comments.length})</Tab>
      </TabList>
      <InlineTabFormWrapper>
        {selectedIndex === 0 && (
          <AddCommentForm
            formId={ADD_COMMENT_FORM}
            onCommentAdd={onCommentAdd}
            disabled={isDisabled}
          />
        )}
      </InlineTabFormWrapper>
      <TabPanels>
        {selectedIndex === 0 && (
          <TabPanel className="p-0">
            <CommentsTab comments={comments} />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  )
}

export default observer(OnboardingTabs)
