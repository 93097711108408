import React, { FC, useEffect, useMemo, useState } from 'react'

import { TrashCan } from '@carbon/icons-react'
import {
  Button,
  Column,
  IconButton,
  Row,
  Select,
  SelectItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from '@carbon/react'
import classNames from 'classnames'

import { PersonCardForList } from '@services/models/person'
import { Task, TaskWatcher } from '@services/models/task'

import { $loader } from '@stores'

import styles from './WatchersTab.module.scss'

type IProps = {
  watchers?: TaskWatcher[]
  persons?: PersonCardForList[]
  readOnly?: boolean
}

const WatchersTab: FC<IProps> = ({ watchers, persons = [], readOnly }) => {
  const [newWatcher, setNewWatcher] = useState('')
  const [invalidNewWatcher, setInvalidNewWatcher] = useState(false)

  function handleAdd() {
    if (!newWatcher) {
      setInvalidNewWatcher(true)
    } else {
      addTaskWatcher()
    }
  }

  const addTaskWatcher = $loader.registerHandler(
    'add-task-watcher',
    () => {},
    () => ({ newWatcher }),
  )

  const handleDelete = $loader.registerHandler(
    'remove-task-watcher',
    () => {},
    (id: string) => ({ taskWatcher: id }),
  )

  const preparedList = useMemo(() => {
    return _.orderBy(watchers, 'person')
  }, [watchers])

  useEffect(() => {}, [persons])
  const availableWatchers = useMemo(() => {
    const watcherNames = watchers?.map((x) => x.person) ?? []
    const filtered = persons.filter((x) => watcherNames.indexOf(x.nickName!) === -1)
    setNewWatcher('')
    return filtered
  }, [persons, watchers])

  return (
    <TableContainer>
      <TableToolbar>
        <TableToolbarContent>
          {!readOnly && (
            <>
              <Select
                id="newWatcher"
                className={classNames('full-height-select')}
                labelText=""
                value={newWatcher}
                hideLabel={true}
                invalid={invalidNewWatcher}
                onChange={(e) => {
                  setInvalidNewWatcher(!e.target.value)
                  setNewWatcher(e.target.value)
                }}
              >
                <SelectItem value="" text="" />
                {availableWatchers.map((x: any, index: number) => (
                  <SelectItem
                    key={[index, x.nickName].join('_')}
                    text={x.nickName}
                    value={x.nickName}
                  />
                ))}
              </Select>
              <Button onClick={handleAdd}>Добавить</Button>
            </>
          )}
        </TableToolbarContent>
      </TableToolbar>
      <Table className={styles.watchersTable}>
        <TableBody>
          {preparedList &&
            preparedList.map((x) => (
              <TableRow key={x.id}>
                <TableCell>{x.person}</TableCell>
                {!readOnly && (
                  <TableCell>
                    <TrashCan
                      onClick={() => {
                        handleDelete(x.id!)
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default WatchersTab
