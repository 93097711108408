import * as React from 'react'
import { FC } from 'react'

import classNames from 'classnames'

import styles from './DefaultSpinner.module.scss'

interface IProps {
  className?: string
}

const DefaultSpinner: FC<IProps> = ({ className }) => {
  return (
    <div className={classNames(styles.ldsRoller, className)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default DefaultSpinner
