interface indexDetails {
  title: string
  active: boolean
  column: number
  tablePosition: number
  id: number
}

/*
  0	generalState	Общее состояние команды
  1	managerInteraction	Взаимодействие с руководителем
  2	teamInteraction	Взаимодействие в команде
  3	awareness	Информированность
  4	evolution	Развитие
  5	currentTasks	Текущие задачи
  6	workMode	Нагрузка, режим работы
  7	salary	Заработная плата, бонусы
  8	communications	 Внутренние коммуникации
  9	finance	Финансовая часть
  10	performance	Производительность
  11	balance	Баланс
  */

export const indexEnumMeta: { [char: string]: indexDetails } = {
  generalState: {
    title: 'Общее состояние команды',
    active: true,
    column: 1,
    tablePosition: 1,
    id: 0,
  },
  managerInteraction: {
    title: 'Взаимодействие с руководителем',
    active: true,
    column: 1,
    tablePosition: 3,
    id: 1,
  },
  teamInteraction: {
    title: 'Взаимодействие в команде',
    active: true,
    tablePosition: 2,
    column: 1,
    id: 2,
  },
  awareness: {
    title: 'Информированность',
    active: false,
    column: 2,
    tablePosition: 4,
    id: 3,
  },
  evolution: {
    title: 'Развитие',
    active: true,
    column: 2,
    tablePosition: 5,
    id: 4,
  },
  currentTasks: {
    title: 'Текущие задачи',
    active: false,
    column: 2,
    tablePosition: 6,
    id: 5,
  },
  workMode: {
    title: 'Нагрузка, режим работы',
    active: false,
    column: 1,
    tablePosition: 8,
    id: 6,
  },
  salary: {
    title: 'Заработная плата, бонусы',
    active: false,
    tablePosition: 7,
    column: 1,
    id: 7,
  },
  communications: {
    title: 'Внутренние коммуникации',
    active: true,
    tablePosition: 4,
    column: 1,
    id: 8,
  },
  finance: {
    title: 'Финансовая часть',
    active: true,
    tablePosition: 6,
    column: 2,
    id: 9,
  },
  performance: {
    title: 'Производительность',
    active: true,
    column: 2,
    tablePosition: 7,
    id: 10,
  },
  balance: {
    title: 'Баланс',
    active: true,
    column: 2,
    tablePosition: 8,
    id: 11,
  },
}
export const indexEnumMetaActive = Object.keys(indexEnumMeta)
  .filter((key) => indexEnumMeta[key].active)
  .reduce((obj: any, key) => {
    obj[key] = indexEnumMeta[key]
    return obj
  }, {})

function getColumn(columnNum: number) {
  return Object.keys(indexEnumMeta)
    .filter((key) => indexEnumMeta[key].column === columnNum && indexEnumMeta[key].active)
    .map((key) => ({ key, ...indexEnumMeta[key] }))
}

export const leftIndexEnumColumn = getColumn(1)

export const rightIndexEnumColumn = getColumn(2)

export const indexIds = Object.keys(indexEnumMeta).reduce((obj: any, key) => {
  obj[key] = indexEnumMeta[key].id
  return obj
}, {})
