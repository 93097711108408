import { SigninSilentArgs, User, UserManager } from 'oidc-client-ts'

import { AppConfig } from '@services/config'

class HcmUserManager extends UserManager {
  config: AppConfig

  constructor(config: AppConfig) {
    super(config.oidcConfig)
    this.config = config
  }

  signinSilent(args?: SigninSilentArgs | undefined): Promise<User | null> {
    const signinArgs = {
      extraTokenParams: this.config.oidcConfig.extraTokenParams,
      ...args,
    }
    return super.signinSilent(signinArgs)
  }
}

export default HcmUserManager
