import React, { useMemo, useState } from 'react'

import {
  Button,
  Checkbox,
  Column,
  ComboBox,
  DatePickerInput,
  /* @ts-ignore */
  FilterableMultiSelect,
  FlexGrid,
  /* @ts-ignore */
  MultiSelect,
  Row,
} from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { useLoader } from '@src/hooks'

import { formatDate } from '@helpers/date'

import HcmDatePicker from '@library/ui/datePicker/HcmDatePicker'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'
import CumulativeChart from '@library/widgets/analytic/tasks/CumulativeChart'
import HistogramChart from '@library/widgets/analytic/tasks/HistogramChart'
import {
  useAuthorFilter,
  useOverduesFilter,
  usePeriodFilter,
  useStatusFilter,
} from '@library/widgets/analytic/tasks/hooks'
import OverduesChart from '@library/widgets/analytic/tasks/OverduesChart'
import TaskAutorsChart from '@library/widgets/analytic/tasks/TaskAutorsChart'
import TaskCauseChart from '@library/widgets/analytic/tasks/TaskCauseChart'
import TaskListTable from '@library/widgets/analytic/tasks/TaskListTable'
import TaskStats from '@library/widgets/analytic/tasks/TaskStats'
import DepartmentSelect from '@library/widgets/department/DepartmentSelect'
import filterStyles from '@library/widgets/filters/styles/Filters.module.scss'

import { dashboardService } from '@services'
import { TaskAnalytics, TaskFilter, TaskFilterTaskStatusEnum } from '@services/models/dashboard'

import { $filter } from '@stores'

import styles from './TaskAnalyticsPage.module.scss'

const TaskAnalyticsPage = () => {
  const { startDate, setStartDate, endDate, setEndDate, resetDates } = usePeriodFilter()
  const { persons, isAuthorsLoading, author, setAuthor } = useAuthorFilter()
  const { options, overdues, setOverdues } = useOverduesFilter()
  const { optionsStatus, statuses, setStatuses } = useStatusFilter()
  const [isHierarchy, setIsHierarchy] = useState<boolean>(true)
  const [isFuncManager, setIsFuncManager] = useState<boolean>(true)
  const [employes, setEmployes] = useState<[]>([])
  const [analytics, setAnalytics] = useState<TaskAnalytics | null>(null)

  const filters = useMemo(
    () =>
      ({
        startDate: formatDate(startDate, { format: 'YYYY-MM-DD' }),
        endDate: formatDate(endDate, { format: 'YYYY-MM-DD' }),
        performers: author ? [author?.text] : undefined,
        overdues: overdues?.map((item) => item.value),
        taskStatus: statuses?.map((item) => item?.value as TaskFilterTaskStatusEnum),
        departmentObjects: $filter.selectedCodes,
        personObjects: employes?.map((item: any) => item.value),
        departmentObjectHierarchy: isHierarchy,
        functionalManagerIncluded: isFuncManager,
      }) as TaskFilter,
    [
      startDate,
      endDate,
      author,
      overdues,
      statuses,
      employes,
      isHierarchy,
      isFuncManager,
      $filter.selectedCodes,
    ],
  )

  const isLoading = useLoader(async () => {
    const response = await dashboardService.getTasksAnalytics(filters)

    if (response.isSuccess && response.data) {
      setAnalytics(response?.data[0])
    }
  }, [filters])

  const handleClearFilter = () => {
    resetDates()
    setAuthor('')
    setOverdues(options)
    setStatuses(optionsStatus)
    setIsHierarchy(true)
    setIsFuncManager(true)
    $filter.SET_SELECTED_CODES([])
    setEmployes([])
  }

  return (
    <>
      <FlexGrid fullWidth>
        <Row className="mt-20">
          <div>
            <p>Период</p>
            <Row>
              <Column className={styles.calendar} key={startDate.format('DD.MM.YYYY')}>
                <HcmDatePicker onChange={setStartDate}>
                  <DatePickerInput
                    id="startDate"
                    size="lg"
                    labelText=""
                    defaultValue={startDate?.format('DD.MM.YYYY')}
                    hideLabel={true}
                    placeholder="начало"
                    onBlur={(e) => {
                      if (!e.target.value) {
                        e.target.value = startDate?.format('DD.MM.YYYY')
                      }
                    }}
                  />
                </HcmDatePicker>
              </Column>
              <Column className={styles.calendar} key={endDate.format('DD.MM.YYYY')}>
                <HcmDatePicker onChange={setEndDate}>
                  <DatePickerInput
                    size="lg"
                    defaultValue={endDate?.format('DD.MM.YYYY')}
                    id="endDate"
                    hideLabel={true}
                    labelText="по"
                    placeholder="окончание"
                    onBlur={(e) => {
                      if (!e.target.value) {
                        e.target.value = endDate?.format('DD.MM.YYYY')
                      }
                    }}
                  />
                </HcmDatePicker>
              </Column>
            </Row>
          </div>
          <Column>
            <p>Исполнитель</p>
            <ComboBox
              /* @ts-ignore */
              id="author-select"
              items={persons}
              itemToString={(item: any) => (item ? item.text : '')}
              onChange={(item: any) => setAuthor(item?.selectedItem)}
              selectedItem={author}
              size="lg"
              disabled={isAuthorsLoading}
              placeholder="Все"
              className={filterStyles.comboBox}
            />
          </Column>
          <Column key={'overdues' + overdues.length}>
            <p>Просрок</p>
            <div className={styles.taskStatusesFilter}>
              <MultiSelect
                id="overdues"
                label={overdues.length === 4 ? 'Все' : overdues.map((item) => item.text).join(', ')}
                onChange={(x: any) => setOverdues(x.selectedItems)}
                initialSelectedItems={overdues}
                items={options}
                itemToString={(option: any) => option.text}
                compareItems={(option: any) => option.id}
                hideLabel={true}
                size="lg"
                className={'multiselect-filter--without-tag'}
              />
            </div>
          </Column>
          <Column key={'statuses' + statuses.length}>
            <p>Статус</p>
            <div className={styles.taskStatusesFilter}>
              <MultiSelect
                id="statuses"
                label={
                  statuses?.length === 3 ? 'Все' : statuses.map((item) => item.shortName).join(', ')
                }
                onChange={(x: any) => setStatuses(x.selectedItems)}
                initialSelectedItems={statuses}
                items={optionsStatus}
                itemToString={(option: any) => option.shortName}
                hideLabel={true}
                size="lg"
                className={'multiselect-filter--without-tag'}
              />
            </div>
          </Column>
        </Row>
        <Row className="mt-20">
          <Checkbox
            id="check-hierarchy"
            labelText="Учитывать иерархию подразделений"
            checked={isHierarchy}
            onChange={() => setIsHierarchy(!isHierarchy)}
          />

          <Checkbox
            id="check-manager"
            labelText="Включить задачи фактических менеджеров"
            checked={isFuncManager}
            onChange={() => setIsFuncManager(!isFuncManager)}
          />
        </Row>
        <Row className="mt-20">
          <Column>
            <p>С кем поработать (подразделения)</p>
            <DepartmentSelect />
          </Column>
        </Row>
        <Row className="mt-20">
          <Column key={'employes' + employes?.length}>
            <p>С кем поработать (сотрудник)</p>
            <div className={styles.employes}>
              <FilterableMultiSelect
                /* @ts-ignore */
                filterable
                id="statuses"
                label={employes?.length ? employes?.map((item: any) => item.text).join(', ') : ''}
                placeholder={
                  employes?.length ? employes?.map((item: any) => item.text).join(', ') : ''
                }
                onChange={(x: any) => setEmployes(x.selectedItems)}
                initialSelectedItems={employes}
                items={persons}
                itemToString={(option: any) => option.text}
                hideLabel={true}
                size="lg"
              />
            </div>
          </Column>
        </Row>
        <Row className="mt-20">
          <Button kind="primary" type="submit" onClick={handleClearFilter}>
            Очистить фильтр
          </Button>
        </Row>
        <WithLoaderWrapper isLoading={isLoading}>
          <Row className="mt-30">
            {!!analytics?.statusMetrics && <TaskStats data={analytics?.statusMetrics} />}
          </Row>
          <Row className="mt-30">
            {!!analytics?.statusCumulative && (
              <CumulativeChart data={analytics?.statusCumulative} startDate={startDate} />
            )}
          </Row>
          <Row className="mt-30">
            {!!analytics?.departmentObjectMetrics && (
              <HistogramChart
                departmentsData={analytics?.departmentObjectMetrics}
                title="С кем поработать (подразделения)"
              />
            )}
          </Row>
          <Row className="mt-30">
            {!!analytics?.departmentObjectMetrics && (
              <HistogramChart
                personsData={analytics?.personObjectMetrics}
                title="С кем поработать (сотрудники)"
              />
            )}
          </Row>
          <Row className="mt-30">
            <Column>
              {!!analytics?.overdueMetrics && <OverduesChart data={analytics?.overdueMetrics} />}
            </Column>
            <Column>
              {!!analytics?.taskCauseMetrics && (
                <TaskCauseChart data={analytics?.taskCauseMetrics} />
              )}
            </Column>
            <Column>
              <div>
                {!!analytics?.topAuthors && <TaskAutorsChart data={analytics?.topAuthors} />}
              </div>
              {!!analytics?.systemTasksCount && (
                <div>Автоматических задач - {analytics?.systemTasksCount || 0}</div>
              )}
            </Column>
          </Row>
          <Row className={`mt-30 ${styles.table}`}>
            <TaskListTable filters={filters} />
          </Row>
        </WithLoaderWrapper>
      </FlexGrid>
    </>
  )
}
export default observer(TaskAnalyticsPage)
