import React, { FC, ReactNode } from 'react'

import { observer } from 'mobx-react-lite'

import InnerErrorPage from '@pages/errors/InnerErrorPage'

type IProps = {
  condition: Function
  children: ReactNode
}
const PermissionsProvider: FC<IProps> = ({ condition, children }) => {
  const hasAccess = _.isFunction(condition) ? condition() : true

  return <>{hasAccess ? children : <InnerErrorPage />}</>
}

export default observer(PermissionsProvider)
