import { makeAutoObservable } from 'mobx'

import { $root as rootStore } from '@stores/index'

class HealthCheckStore {
  $root

  constructor(store: typeof rootStore) {
    makeAutoObservable(this)
    this.$root = store
  }

  lastOpenedId: string = ''
  setLastOpenedId(lastOpenedId: string) {
    this.lastOpenedId = lastOpenedId
  }
}

export default HealthCheckStore
