import React, { FC, useState } from 'react'

import {
  DataTable,
  PaginationNav,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { useLoader } from '@src/hooks'

import { formatDate } from '@helpers/date'

import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { humanTaskService } from '@services'
import { TaskFilter } from '@services/models/dashboard'
import { TaskFiltered } from '@services/models/task/task-filtered'

const limit = 10.0

interface Props {
  filters: TaskFilter
}

const text: { [key: string]: string } = {
  todo: 'Сделать',
  done: 'Готово',
  inprogress: 'В работе',
}

const TaskListTable: FC<Props> = ({ filters }) => {
  const [page, setPage] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [taskList, setTaskList] = useState([])
  const [sortBy, setSortBy] = useState<string | undefined>(undefined)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  const getTasks = async () => {
    const offset = page * limit
    const response = await humanTaskService.getTaskForAnalytics(
      filters,
      limit,
      offset,
      sortBy,
      sortOrder,
    )

    if (response.isSuccess && response.data?.data && response.data?.paging) {
      setTaskList(response.data?.data)
      setTotalItems(response.data?.paging.count)
    } else {
      setTaskList([])
      setTotalItems(0)
    }
  }

  const isLoading = useLoader(async () => {
    getTasks()
  }, [filters, page, sortBy, sortOrder])

  const headers = [
    { key: 'code', header: 'Код' },
    { key: 'createDate', header: 'Дата создания' },
    { key: 'dueDate', header: 'Выполнить до' },
    { key: 'personObject', header: 'С кем поработать' },
    { key: 'departmentObject', header: 'Подразделение' },
    { key: 'causeType', header: 'Причина задачи' },
    { key: 'author', header: 'Автор' },
    { key: 'performer', header: 'Исполнитель' },
    { key: 'status', header: 'Статус' },
  ]

  const rows = taskList.map((task: TaskFiltered, index) => ({
    id: task?.id ?? `${index}`,
    code: task?.code,
    createDate: formatDate(task?.createDate),
    dueDate: formatDate(task?.dueTo),
    personObject: task?.personObject ? task?.personObject?.nickName : task?.departmentObject?.name,
    departmentObject: task?.personObject ? task?.personObject?.departmentName : '',
    causeType: task.taskCause,
    author: task?.author,
    performer: task?.performer?.nickName,
    status: task?.status ? text[task?.status] : '',
  }))

  const handleHeaderClick = (headerKey: string) => {
    if (sortBy === headerKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(headerKey)
      setSortOrder('asc')
    }
  }

  return (
    <WithLoaderWrapper isLoading={isLoading}>
      <DataTable headers={headers} rows={rows} isSortable={true} size="lg">
        {({
          rows,
          headers,
          getTableProps,
          getTableContainerProps,
          getHeaderProps,
          getRowProps,
        }) => {
          return (
            <TableContainer {...getTableContainerProps()}>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        onClick={() => handleHeaderClick(header.key)}
                        isSortHeader={sortBy === header.key}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.length > 0 ? (
                    rows.map((row) => (
                      <TableRow {...getRowProps({ row })}>
                        {row.cells.map((cell) => (
                          <TableCell key={row.id + cell.id}>{cell.value}</TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="mt-20">
                      <TableCell>Нет записей</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }}
      </DataTable>
      <PaginationNav page={page} totalItems={Math.ceil(totalItems / limit)} onChange={setPage} />
    </WithLoaderWrapper>
  )
}

export default observer(TaskListTable)
