import React, { FC, useEffect, useState } from 'react'

import { Column, Row, Select, SelectItem, Stack } from '@carbon/react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import Title from '@library/ui/title/Title'
import { ModalBase, ModalBody, ModalHeader } from '@library/utils/modals/index'
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store'

import { UsersRolesListUserRoles } from '@services/models/userPermissions'

import styles from './ViewRoleModal.module.scss'

interface IProps {
  role: UsersRolesListUserRoles
  nick: string
  _core: IModalItemCore
}

const ViewRoleModal: FC<IProps> = ({ role, nick, _core }) => {
  const [departments, setDepartments] = useState<string[] | null>(null)
  const isManager = role?.role === 'MANAGER'
  const isManagerFact = role?.role === 'MANAGERFACT'

  useEffect(() => {
    if (isManager && role?.subDepartmentsName) {
      setDepartments(role?.subDepartmentsName)
    }

    if (isManagerFact && role?.subPersons) {
      setDepartments(role?.subPersons)
    }
  }, [role, nick])

  return (
    <ModalBase>
      <ModalHeader>
        <Title size="h2">Просмотр роли</Title>
      </ModalHeader>
      <ModalBody className={'mb-20'}>
        <form id="roles-form">
          <Stack gap={6}>
            <Select id="login" key={nick} labelText="Ник" defaultValue={nick} disabled={true}>
              <SelectItem key={nick} text={nick} value={nick} />
            </Select>
            <Select
              id="role"
              key={role?.role}
              labelText="Роль"
              defaultValue={{ label: role?.roleName, id: role?.role }}
              disabled={true}
            >
              {role?.roleName && <SelectItem key={0} text={role.roleName} value={role?.role} />}
            </Select>

            {(isManager || isManagerFact) && (
              <div className={styles.departmentList}>
                <Column className={styles.list}>
                  {departments && departments?.length > 0 ? (
                    departments?.map((item) => (
                      <Row className={classNames(styles.row)} key={item}>
                        <p>{item}</p>
                      </Row>
                    ))
                  ) : (
                    <Row className={styles.info}>
                      <p>Подразделения не назначены</p>
                    </Row>
                  )}
                </Column>
              </div>
            )}
          </Stack>
        </form>
      </ModalBody>
    </ModalBase>
  )
}

export default observer(ViewRoleModal)
