import React from 'react'
import { Outlet } from 'react-router-dom'

import { useStopInitialLoading } from '../layout.hook'
import styles from './SimpleLayout.module.scss'

const SimpleLayout = () => {
  useStopInitialLoading(true)

  return (
    <div className={styles.wrapper}>
      <Outlet />
    </div>
  )
}

export default SimpleLayout
