import React, { FC } from 'react'
import { Bar } from 'react-chartjs-2'

import 'chart.js/auto'
import { observer } from 'mobx-react-lite'

import { TaskAnalyticsTopAuthors } from '@services/models/dashboard'

type Props = {
  data: TaskAnalyticsTopAuthors[]
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'ТОП-5 авторов задач',
      position: 'top' as const,
      color: '#000000',
      padding: 10,
      font: { size: 16 },
    },
  },
  y: {
    beginAtZero: true,
    ticks: {
      stepSize: 1,
    },
  },
}

const TaskAutorsChart: FC<Props> = ({ data }) => {
  const chartData = {
    labels: data.map((item) => item.author),
    datasets: [
      {
        label: 'Количество задач',
        data: data.map((item) => item.count),
        backgroundColor: 'rgba(89, 229, 0, 1)',
        barThickness: 30,
        skipNull: true,
      },
    ],
  }

  return (
    <>
      <Bar data={chartData} options={options} height={300} />
    </>
  )
}

export default observer(TaskAutorsChart)
