/* tslint:disable */

/* eslint-disable */

/**
 * notification
 * Отправка уведомлений пользователям
 *
 * OpenAPI spec version: 1.0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FeedbackDataAttachment } from './feedback-data-attachment'

/**
 *
 *
 * @export
 * @interface FeedbackData
 */
export interface FeedbackData {
  /**
   * Имя автора
   *
   * @type {string}
   * @memberof FeedbackData
   */
  author?: string

  /**
   * Никнейм пользователя
   *
   * @type {string}
   * @memberof FeedbackData
   */
  personNickName?: string

  /**
   * Тип обращения
   *
   * @type {string}
   * @memberof FeedbackData
   */
  type?: FeedbackDataTypeEnum

  /**
   * Тема обращения
   *
   * @type {string}
   * @memberof FeedbackData
   */
  subject?: string

  /**
   * Описание обращения
   *
   * @type {string}
   * @memberof FeedbackData
   */
  description?: string

  /**
   * Вложения (список объектов)
   *
   * @type {Array<FeedbackDataAttachment>}
   * @memberof FeedbackData
   */
  attachment?: Array<FeedbackDataAttachment>
}

/**
 * @export
 * @enum {string}
 */
export enum FeedbackDataTypeEnum {
  Incident = 'incident',
  Suggestion = 'suggestion',
}
