import React, { FC, ReactNode } from 'react'

import { Button, ButtonSet, InlineLoading } from '@carbon/react'
import { observer } from 'mobx-react-lite'

import Title from '@library/ui/title/Title'
import { ModalBase, ModalBody, ModalFooter, ModalHeader } from '@library/utils/modals/index'
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store'
import { IModalBaseProps } from '@library/utils/modals/ui/ModalBase'

import { $loader } from '@stores'

import styles from './CustomAlertModal.module.scss'

interface IProps {
  title?: string
  message: string
  onDoneText?: string
  onDone?: Function
  onDoneButtonType?: string
  onCancelText?: string | ReactNode
  onCancel?: Function
  onCancelButtonType?: string
  modalOptions: IModalBaseProps
  loaderName?: string
  _core: IModalItemCore
}

const CustomAlertModal: FC<IProps> = ({
  title,
  message,
  onDone,
  onDoneText = 'Подтвердить',
  onDoneButtonType,
  onCancel,
  onCancelText = 'Отменить',
  onCancelButtonType = 'tertiary',
  modalOptions,
  loaderName = 'custom-alert-done',
  _core,
}) => {
  const handleOnDone = $loader.registerHandler(loaderName, async () => {
    if (_.isFunction(onDone)) {
      await onDone()
    }
    _core.hide()
  })

  async function handleOnCancel() {
    if (_.isFunction(onCancel)) {
      await onCancel()
    }
    _core.hide()
  }

  return (
    <ModalBase {...modalOptions}>
      {title && (
        <ModalHeader>
          <Title size="h2">{title}</Title>
        </ModalHeader>
      )}
      <ModalBody className={styles.body}>{message}</ModalBody>
      {(onDone || onCancel) && (
        <ModalFooter>
          <ButtonSet>
            {onCancelText && (
              // @ts-ignore
              <Button kind={onCancelButtonType} onClick={handleOnCancel}>
                {onCancelText}
              </Button>
            )}
            {onDoneText && (
              <Button
                // @ts-ignore
                kind={onDoneButtonType}
                disabled={$loader.isRunHandler(loaderName)}
                onClick={handleOnDone}
              >
                {$loader.isRunHandler(loaderName) ? 'Загрузка...' : onDoneText}
              </Button>
            )}
          </ButtonSet>
        </ModalFooter>
      )}
    </ModalBase>
  )
}

export default observer(CustomAlertModal)
