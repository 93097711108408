import { useMemo, useState } from 'react'

import { toJS } from 'mobx'

import { $catalog } from '@stores'
import { IFeedbackStatus } from '@stores/catalogStore'

export const useStatusFilter = () => {
  const optionsStatus = useMemo(() => toJS($catalog.feedbackStatus), [$catalog.feedbackStatus])
  const [statuses, setStatuses] = useState<IFeedbackStatus[]>([])

  return {
    statuses,
    setStatuses,
    optionsStatus,
  }
}
