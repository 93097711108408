import { makeAutoObservable } from 'mobx'

import ModalViewerStore from '@library/utils/modals/ModalViewer.store'

import CabinetStore from '@stores/cabinetStore'
import GlobalStore from '@stores/globalStore'
import LoaderStore from '@stores/loaderStore'
import UserStore from '@stores/userStore'

import AppFilterStore from './appFilterStore'
import CatalogStore from './catalogStore'
import HealthCheckStore from './healthCheckStore'

class RootStore {
  constructor() {
    makeAutoObservable(this)
    this.$global = new GlobalStore(this)
    this.$loader = new LoaderStore(this)
    this.$modal = ModalViewerStore
    this.$user = new UserStore(this)
    this.$catalog = new CatalogStore(this)
    this.$cabinet = new CabinetStore(this)
    this.$filter = new AppFilterStore(this)
    this.$hc = new HealthCheckStore(this)
  }

  $global
  $loader
  $modal
  $user
  $catalog
  $cabinet
  $filter
  $hc
}

export const $root = new RootStore()
export const $global = $root.$global
export const $loader = $root.$loader
export const $modal = $root.$modal
export const $user = $root.$user
export const $catalog = $root.$catalog
export const $cabinet = $root.$cabinet
export const $filter = $root.$filter
export const $hc = $root.$hc
