import React, { FC } from 'react'
import { Bar } from 'react-chartjs-2'

import 'chart.js/auto'
import { observer } from 'mobx-react-lite'

import { TaskAnalyticsTaskCauseMetrics } from '@services/models/dashboard'

type Props = {
  data: TaskAnalyticsTaskCauseMetrics[]
}

const options = {
  indexAxis: 'y' as const,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Причины появления задач',
      position: 'top' as const,
      color: '#000000',
      padding: 10,
      font: { size: 16 },
    },
  },
  x: {
    beginAtZero: true,
    ticks: {
      stepSize: 1,
    },
  },
}

const TaskCauseChart: FC<Props> = ({ data }) => {
  const chartData = {
    labels: data.map((item) => item.cause),
    datasets: [
      {
        label: 'Количество задач',
        data: data.map((item) => item.count),
        backgroundColor: 'rgba(33, 112, 250, 1)',
        barThickness: 30,
        skipNull: true,
      },
    ],
  }

  return <Bar data={chartData} options={options} height={300} />
}

export default observer(TaskCauseChart)
