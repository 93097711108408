import React, { useMemo, useState } from 'react'

import {
  Column,
  DataTable,
  Row,
  Search,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { useLoader, usePagination } from '@src/hooks'

import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { devPlanService } from '@services'
import { JobProfile } from '@services/models/health-check'

const JobProfilesPage = () => {
  const [list, setList] = useState<JobProfile[]>([])
  const [query, setQuery] = useState('')
  const { sortBy, sortOrder, handleHeaderClick } = usePagination()

  const isLoading = useLoader(async () => {
    const response = await devPlanService.getJobProfiles()

    if (response.isSuccess && response?.data?.data) {
      const sortedList = _.orderBy(response?.data?.data, 'name', 'asc')
      setList(sortedList)
    }
  }, [])

  const headers = [
    { key: 'name', header: 'Наименование', isSortable: true },
    { key: 'link', header: 'Ссылка на источник' },
  ]

  const filteredData = useMemo(() => {
    return list?.filter((item) => item?.name?.toLowerCase().includes(query.toLowerCase()))
  }, [query, list])

  const rows = useMemo(
    () =>
      _.orderBy(
        filteredData,
        [(item) => (item.name ? item.name.toLowerCase() : '')],
        sortOrder as 'asc' | 'desc',
      )?.map((item: JobProfile, index) => ({
        id: `${index}-${item?.profileToken}`,
        name: item?.name,
        link: (
          <a href={item?.link} target="_blank" rel="noreferrer">
            Посмотреть профиль в AIDAY
          </a>
        ),
      })),
    [sortBy, sortOrder, filteredData],
  )

  const handleSearch = _.debounce((e: any) => {
    setQuery(e.target.value)
  }, 100)

  return (
    <WithLoaderWrapper isLoading={isLoading}>
      <Row className="mb-20">
        <Column>
          <Search
            size="lg"
            defaultValue={query}
            placeholder="Поиск по наименованию"
            labelText="Поиск"
            closeButtonLabelText="Очистить"
            id="job-search"
            onChange={handleSearch}
          />
        </Column>
        <Column></Column>
      </Row>

      <DataTable headers={headers} rows={rows} isSortable={true} size="lg">
        {({
          rows,
          headers,
          getTableProps,
          getTableContainerProps,
          getHeaderProps,
          getRowProps,
        }) => {
          return (
            <TableContainer {...getTableContainerProps()}>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        isSortable={header.isSortable}
                        onClick={() => handleHeaderClick(header.key)}
                        isSortHeader={sortBy === header.key}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.length > 0 ? (
                    rows.map((row) => (
                      <TableRow {...getRowProps({ row })}>
                        {row.cells.map((cell) => (
                          <TableCell key={row.id + cell.id}>{cell.value}</TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="mt-20">
                      <TableCell colSpan={headers.length}>Нет записей</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }}
      </DataTable>
    </WithLoaderWrapper>
  )
}

export default observer(JobProfilesPage)
