import React, { FC, useEffect, useState } from 'react'

import { Add, Subtract } from '@carbon/icons-react'
import { Tag } from '@carbon/react'
import classNames from 'classnames'

import { StructureTreeNode } from '@pages/reference/structureTree/StructureTree.types'

import styles from './StructureTree.module.scss'

interface IProps {
  data: StructureTreeNode
  onClick: (event: React.MouseEvent<HTMLElement>, nickName: string) => void
  parentData?: StructureTreeNode
  depth?: number
  minVisibleDepth?: number
  searchQuery?: string
}

const StructureTree: FC<IProps> = ({
  data,
  onClick,
  parentData,
  depth = 1,
  minVisibleDepth = 1,
  searchQuery = '',
}) => {
  const isDepartment = data.itemType === 'department'

  const hasChildren = data?.children!.length > 0
  const hasPersons = isDepartment && data?.persons!.length > 0
  const hasAnyChildren = hasChildren || hasPersons

  const getDefaultState = () => (hasAnyChildren && depth <= minVisibleDepth ? true : null)
  const [open, setOpen] = useState(getDefaultState())
  const isOpen = (open === null && !!searchQuery) || open
  useEffect(() => setOpen(getDefaultState()), [searchQuery])

  function getChildId(child: StructureTreeNode) {
    return child.itemType === 'department' ? child.code : child.nickName
  }

  const highlightText = (str: string) => {
    if (!searchQuery) return str
    const needles = searchQuery.toLowerCase().split(/[\s()]+/)
    const regex = new RegExp(`(${needles.join('|')})`, 'gi')
    const parts = str.split(regex)

    return parts.map((part, index) =>
      needles.includes(part?.toLowerCase()) ? (
        <span key={index} style={{ backgroundColor: 'yellow' }}>
          {part}
        </span>
      ) : (
        part
      ),
    )
  }

  return (
    <div className={classNames(styles.wrapper, styles['level-' + (depth - 1)])}>
      {data.itemType === 'department' ? (
        <div
          className={classNames(
            styles.card,
            data.cardNotBright && styles.notBright,
            styles[data.cardStyle!],
            styles.isDepartment,
          )}
        >
          <div className={styles.title}>
            {highlightText(data.name!)}{' '}
            {data.manager && (
              <div
                className={styles.linkTag}
                title="Руководитель"
                onClick={(e) => onClick(e, data.manager!)}
              >
                <Tag type="purple" className={styles.isManager}>
                  {data.manager}
                </Tag>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            styles.card,
            styles.isPerson,
            // data.nickName === parentData?.manager && styles.isManager,
          )}
          onClick={(e) => onClick(e, data.nickName!)}
        >
          <div className={styles.title}>
            {highlightText(data.nickName!)}{' '}
            <span className={styles.titleMeta}> ({highlightText(data.name!)})</span>
          </div>
          {data.jobPosition && (
            <div className={styles.subTitle}>{highlightText(data.jobPosition!)}</div>
          )}
          {data.functionalManager && (
            <div
              className={styles.linkTag}
              title="Фактический руководитель"
              onClick={(e) => onClick(e, data.functionalManager!)}
            >
              <Tag type="cool-gray" className={styles.nickName}>
                {data.functionalManager}
              </Tag>
            </div>
          )}
        </div>
      )}

      {hasAnyChildren && (
        <>
          <div className={classNames(styles.button)} onClick={() => setOpen(!isOpen)}>
            {isOpen ? <Subtract /> : <Add />}
          </div>
          {isOpen && (
            <>
              {hasPersons && (
                <div
                  className={classNames(
                    styles.persons,
                    data?.persons!.length === 1 && styles.personsSingle,
                  )}
                >
                  {data.persons!.map((child) => (
                    <div className={styles.personsItem} key={getChildId(child)}>
                      <StructureTree
                        data={child}
                        onClick={onClick}
                        parentData={data}
                        depth={depth + 1}
                        searchQuery={searchQuery}
                      />
                    </div>
                  ))}
                </div>
              )}
              {hasChildren && (
                <div
                  className={classNames(
                    styles.departments,
                    data?.children!.length === 1 && styles.departmentsSingle,
                  )}
                >
                  {data.children!.map((child) => (
                    <div className={styles.departmentsItem} key={getChildId(child)}>
                      <StructureTree
                        data={child}
                        onClick={onClick}
                        parentData={data}
                        depth={depth + 1}
                        searchQuery={searchQuery}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default StructureTree
