export const dateFormats = {
  date: 'DD.MM.YYYY',
  dateWithoutYear: 'DD.MM',
  dateTime: 'DD.MM.YYYY HH:mm:ss',
  dateTimeShort: 'DD.MM.YYYY HH:mm:ss',
  textDateWithoutYear: 'DD MMMM',
  quickDateInput: 'DDMMYYYY',
  isoDate: 'YYYY-MM-DD',
}

// todo: remove?
export const USER_ROLES = [
  'EMPLOYEE',
  'MANAGER',
  'MANAGERFACT',
  'MANAGERCUSTOM',
  'HRPARTNER',
  'HRDEV',
  'SUPERUSER',
]

// todo: remove?
export const ROLES_GROUPS = ['EMPLOYEE', 'MANAGER', 'HRPARTNER', 'HRDEV', 'SUPERUSER']
export const UNAVAILABLE_ROLE_HCM_ADD = ['SYSTEM', 'EMPLOYEE', 'MANAGER', 'MANAGERFACT']

export const SUPERUSER = 'SUPERUSER'
export const HRDEV = 'HRDEV'
export const EMPLOYEE = 'EMPLOYEE'

export const USER_ROLES_MAPPING = [
  {
    id: 'EMPLOYEE',
    label: 'EMPLOYEE',
  },
  {
    id: 'MANAGER',
    label: 'MANAGER',
  },
  {
    id: 'HRPARTNER',
    label: 'HRPARTNER',
  },
  {
    id: 'HRDEV',
    label: 'HRDEV',
  },
  {
    id: 'SUPERUSER',
    label: 'SUPERUSER',
  },
]

// https://docs.google.com/spreadsheets/d/1uMwlYOFgSx1kPd23m1wvgFn-zUtDVQPcsjtsb0iIwuw/edit#gid=0
export const permissionsList = [
  'PERM_USER_ROLE_ADD',
  'PERM_ABSENCE_READ',
  'PERM_ABSENCE_MANAGER_READ',
  'PERM_ANALYTICS_IPR',
  'PERM_ANALYTICS_TASKS',
  'PERM_ANALYTICS_HEALTHCHECK',
  'PERM_ANALYTICS_ONBOARDING',
  'PERM_HEALTHCHECK_FULL',
  'PERM_HEALTHCHECK_MANAGER_READ',
  'PERM_IPR_READ',
  'PERM_IPR_MANAGER',
  'PERM_IPR_ADD',
  'PERM_IPR_EDIT',
  'PERM_IPR_DELETE',
  'PERM_MYDEPARTMENTS_READ',
  'PERM_MYDEPARTMENTS_MANAGER_READ',
  'PERM_NOTIFICATIONS',
  'PERM_TASK_READ',
  'PERM_TASK_EDIT',
  'PERM_TASK_DELETE',
  'PERM_TASK_ADD',
  'PERM_TASK_CURRENTAUTHOR_READ',
  'PERM_TASK_CURRENTAUTHOR_EDIT',
  'PERM_TASK_MANAGER',
  'PERM_TASK_HC',
  'PERM_TASK_DUETO_EDIT',
  'PERM_TASK_REVIEW',
  'PERM_TASK_WATCHER_EDIT',
  'PERM_TASK_PERSONAL',
  'PERM_PLAN_PERFORMANCE_READ',
  'PERM_PLAN_PERFORMANCE_MANAGER_READ',
  'PERM_SKILL_REVIEW_READ',
  'PERM_SKILL_REVIEW_MANAGER_READ',
  'PERM_ONBOARDING_SELF_READ',
  'PERM_ONBOARDING_READ',
  'PERM_ONBOARDING_ADD',
  'PERM_ONBOARDING_EDIT',
  'PERM_ONBOARDING_DELETE',
  'PERM_ONBOARDING_MANAGER',
  'PERM_EQUIPMENT_READ',
  'PERM_EQUIPMENT_MANAGER_READ',
  'PERM_TRANSITIONS_READ',
  'PERM_TRANSITIONS_MANAGER_READ',
  'PERM_MYEMPLOYEES_MANAGER_READ',
  'PERM_DEPARTMENTS_MANAGER_READ',
  'PERM_JOBPROFILES',
] as const

export type PermissionsListType = (typeof permissionsList)[number]

export const formErrors = {
  required: 'Поле обязательно',
  invalidValue: 'Неверное значение',
  somethingWentWrong: 'Что-то пошло не так. Попробуйте пожалуйста позже.',
  forbidden: 'Доступ запрещен.',
  dateBeforeToday: 'Дата должна быть больше текущей',
  invalidPeriod: 'Период задан неверно',
  titleWithoutLink: 'Нельзя сохранить текст ссылки без самой ссылки',
  invalidUrl: 'Неправильный формат ссылки',
}

export const formMessages = {
  notificationsUpdated: 'Настройки уведомлений сохранены.',
}

export const FREE_TEXT_CAUSE = '1'
export const HEALTHCHECK_CAUSE = '2'
export const PERFORMANCE_CAUSE = '3'
export const COMPETENCE_CAUSE = '4'
export const ONBOARDING_CAUSE = '5'
export const CAUSES_AVAILABLE_FOR_TASK_REVIEWERS = ['1', '3', '4']
